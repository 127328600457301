/**
 * Home page
 * 
 * @flow
 */
import React from 'react';
import HomeFeature from '../../components/home-feature/HomeFeature';
import type BugsnagClient from '@bugsnag/js';
import './Home.css';

type Props = {
  jest: boolean,
  bugsnagClient: BugsnagClient
};

class Home extends React.Component<Props> {
  static defaultProps = {
    jest: false,
  };

  render () {
    return (
      <div className="home">
        <HomeFeature />
      </div>
    );
  }
}

export default Home;
