/**
 * Image Heading
 * @flow
 */
import React from 'react';
import { IMAGE_ROOT } from '../../data/Data';
import './ImageHeading.css';
const DEFAULT = `hokusai_waves_narrow.jpg`;

type Props = {
  embed: boolean,
  image: string,
  title: string,
};

type State = {
  width: number,
  height: number,
}

class ImageHeading extends React.Component<Props, State>{
  static defaultProps = {
    embed: false,
    image: '',
    title: '',
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  render() {
    let { embed, image, title } = this.props;
    const { width } = this.state;
    embed = embed ? embed : null;
    image = image ? image : DEFAULT;
    const small = (width < 768) ? true : false;
    const heading = (
      <div className="section-heading">
        { embed ? <h2>{title}</h2> : <h1>{title}</h1>}
      </div>
    );

    let innerHeading = small ? null : heading ;
    let outerHeading = small ? heading : null ;    

    return (
      <React.Fragment>
        <div className="image-heading" >
          <div className="background-image" style={{ backgroundImage: `url(${IMAGE_ROOT}${image})`}} />
          {innerHeading}
        </div>
        {outerHeading}
      </React.Fragment>
    );
  }
}

export default ImageHeading;
