/**
 * Aikido Maai App
 * 
 * @flow
 */
import React from 'react';
import localforage from 'localforage';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import { library } from '@fortawesome/fontawesome-svg-core';
import Main from './components/main/Main'

import './App.css';

// Setup fontawesome
import {
  faBars,
  faBell,
  faCalendarCheck,
  faClock,
  faComment,
  faCircle,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faExclamationTriangle,
  faEye,
  faHome,
  faMobileAlt,
  faInfoCircle,
  faTimes,
  faUserNinja,
  faPlus,
  faMinus,
  faMapMarker,
  faSearch,
  faEllipsisH,
  faSpinner,
  faCheck,
  faShoppingCart,
  faFilter,
  faCreditCard,
  faLock,
  faStar,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faBars,
  faBell,
  faCalendarCheck,
  faClock,
  faComment,
  faCircle,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faExclamationTriangle,
  faEye,
  faHome,
  faInfoCircle,
  faMapMarker,
  faMobileAlt,
  faTimes,
  faUserNinja,
  faPlus,
  faMinus,
  faSearch,
  faEllipsisH,  
  faSpinner,
  faCheck,
  faShoppingCart,
  faFilter,
  faCreditCard,
  faLock,
  faStar,
);

// Setup bugsnag
const bugsnagClient = bugsnag('badf970735213b06df97eab72125ef91');
bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin('react');

// Configure localforage
localforage.config({
  driver      : localforage.INDEXEDDB,
  name        : 'Aikido Maai',
  version     : 1.0,
  storeName   : 'store', // Should be alphanumeric, with underscores.
  description : 'Browser local storage for Aikido Maai'
});

type Props = {};
type State = {
  bugsnagClient: {},
  localforage: {
    getitem : (item: string) => {},
    setItem: (item: string) => {},
  },
};

class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      localforage,
      bugsnagClient
    }
  }

  render() {
    return (
      <ErrorBoundary>
        <Main {...this.state} />
      </ErrorBoundary>
    );
  }
}

export default App;
