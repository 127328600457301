import React from 'react';
import ImageHeading from '../image/ImageHeading';
import './FourOhFour.css';

const IMAGE = 'aikido-maai-404.jpg';

class FourOhFour extends React.Component {
  render() {
    return (
      <article className="404">
        <ImageHeading image={IMAGE} title="Whoops"/>
        <section>
          <p>
            We are terribly sorry. But the page you are looking for cannot be found.
          </p>
          <p>
            Geeks call this an error 404
          </p>
        </section>
      </article>
    )
  } 
}

export default FourOhFour;