export const PRODUCTS = [
  {
    name: "Single class",
    alternateName: 'Single class',
    brand: 'Aikido Maai',
    price: 20,
    description: "Training fee for one class, adults, or kids.",
    subscription: false,
    sku: {
      prod: "prod_IM7T67kDO06uGp",
      dev: "prod_IM7TbUDqg8SSUW",
    },
    id: 'single-class',
    image: 'covid-safe-aikido-weapons-sqare.jpg',
    tags: ['weapons', 'classes', 'kids', 'adult', 'lgbtiq friendly']
  },
  {
    name: "5 classes",
    alternateName: 'Five classes',
    brand: 'Aikido Maai',
    price: 100,
    description: "Training fees for five weapons or open handed classes.",
    subscription: false,
    sku: {
      prod: "prod_ITdGKn3EEAv8Ef",
      dev: "prod_ITdHbP5W2FfbiM",
    },
    id: 'five-classes-bundle',
    image: 'aikido-sankyo-ura-sq.jpg',
    tags: ['weapons', 'classes', 'adult', 'lgbtiq friendly']
  },
  {
    name: "Adult training monthly subscription",
    alternateName: 'Adult monthly subscription',
    brand: 'Aikido Maai',
    price: 140,
    description: "Monthly training fees for adults earning a wage who can attend the basics, weapons or advanced classes. Fees are charged on a monthly recurring basis automatically through Stripe payment gateway.",
    subscription: true,
    sku: {
      prod: 'plan_GTckXECNVYsfBB',
      dev: 'plan_FWqw09E77AB1OF'
    },
    id: 'adult-monthly-subscription',
    image: 'aikido-weapons-class-400x400.jpg',
    tags: ['classes', 'adult', 'lgbtiq friendly']
  },
  {
    name: "Student / teen / concession training monthly subscription",
    alternateName: 'Student monthly subscription',
    brand: 'Aikido Maai',
    id: 'student-monthly-subscription',
    price: 120,
    subscription: true,
    description: "Monthly training fees for students, teens or concession card holders who are attending the basics, weapons or advanced classes. Fees are charged on a monthly recurring basis automatically through Stripe payment gateway.",
    sku: {
      prod: 'monthly-student',
      dev: 'plan_G2mlyqtqs2VILA'
    },
    image: 'aikido-basics-open-handed.jpg',
    tags: ['classes', 'adult', 'teen', 'lgbtiq friendly', 'concession']
  },
  {
    name: "Adult/teen gi",
    alternateName: 'Gi',
    brand: 'Tans Martial Arts',
    id: 'adult-teen-gi',
    price: 85,
    description: "Training uniform for children. Strong poly cotton blend, and easy to wash",
    sku: {
      prod: 'sku_G6006neGxejutL',
      dev: 'prod_FWqyM6nziC1Jfw'
    },
    image: 'gi.jpg',
    tags: ['gi', 'teen', 'adult']
  },
  {
    name: "Children's gi",
    alternateName: 'Gi',
    brand: 'Tans Martial Arts',
    id: 'childrens-gi',
    price: 65,
    description: "Training uniform for children. Strong poly cotton blend, and easy to wash",
    sku: {
      prod: 'sku_G601fwrykmNlPE',
      dev: 'sku_FWqzBI16Roso4B'
    },
    image: 'gi.jpg',
    tags: ['gi', 'kids']
  },
];

export const SHOP_IMAGE = 'aikido-maai-shop.jpg';
export const KIDS_CLASSES = 'aikido-kids-classes.jpg';