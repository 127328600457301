/**
 * Kids Aikido landing page
 * 
 * @flow
 */
import React from 'react';
import {Helmet} from 'react-helmet';
import ImageHeading from '../image/ImageHeading';
import InterestForm from '../forms/InterestForm';
import Location from '../location/Location';
import { LANDING_PAGES } from './LandingPages.data';
import type BugsnagClient from '@bugsnag/js';
import type LocalForage from 'localforage';
import './LandingPage.css';

type Props = {
  bugsnagClient: BugsnagClient,
  localforage: LocalForage,
  location: {
    pathname: string
  }
};

class LandingPage extends React.Component<Props> {

  render() {
    const content = [...LANDING_PAGES[1].content];
    const images = [
      LANDING_PAGES[1].image,
      'aikido-kids-classes-wide.jpg',
      'aikido-kids-grading-photo-2019.jpg'
    ];
    let i=0;

    const {location} = this.props;
    // All Geo urls are formatted like `childrens-martial-arts-{suburb}`
    const slug = location.pathname.substr(23);
    const geo = slug.split('-');
    let suburb = '';
    for (let i=0; i<geo.length; i++) {
      suburb += `${geo[i].substr(0,1).toUpperCase()}${geo[i].substr(1)} `;
    }
    suburb = suburb.trim();

    const title = `Children's Martial Arts, Aikdio, ${suburb}`;
    const description = `Kids from ${suburb} learn confidence and listeneng skills at Aikido Maai`;

    return (
      <div className="page landing kids geo">
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
        <ImageHeading  title={`Kid's Martial Arts ${suburb}`} image={images[i]} />
        <section>
          <div className="geo">
            <p><strong>Your kids can learn martial arts ({suburb})</strong> with Aikido Maai{suburb !== 'Mt Waverley' ? ' in nearby,  Mt Waverley' : ''}. If you want your kids to get fit, have fun and learn some practical defence skills, then Aikido is for them. <strong>Claim your child's free class now</strong>, and they try Aikido out. We run a fun inclusive and safe training space.</p>
          </div>
          <div dangerouslySetInnerHTML={{__html: content[i]}} />
          <InterestForm {...this.props} />
        </section>
        <ImageHeading image={images[++i]} title="Class details" embed={true} />
        <section>
          <div dangerouslySetInnerHTML={{__html: content[i]}} />
          <InterestForm {...this.props} />
        </section>
        <ImageHeading image={images[++i]} title="Sensei" embed={true} />
        <section>
          <div dangerouslySetInnerHTML={{__html: content[i]}} />
          <InterestForm {...this.props} />
        </section>
        <Location embed={true} />
      </div>
    );
  }
}

export default LandingPage;