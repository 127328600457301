export const DEFAULT_FORM = {
  name: 'Default Form',
  title: 'Default',
  id: 'default',
  className: 'default',
  inputs: [
    {
      className: 'name',    
      type: 'text',
      label: 'Name',
      name: 'name',
      placeholder: 'Your name',
      required: true,
    },
    {
      className: 'email',    
      type: 'email',
      label: 'Email',
      name: 'email',
      placeholder: 'you@example.com',
      required: true,
    },
    {
      className: 'mobile',    
      type: 'phone',
      label: 'Mobile',
      name: 'mobile',
      placeholder: '0404 123 456',
      required: true,
    },
    {
      className: 'child-name',
      conditional: false,
      type: 'text',
      label: 'Default',
      name: 'message',
      placeholder: '',
      value: 'dEfault'
    },
  ],
  success: {
    message: "You are successfully default!"
  }
}