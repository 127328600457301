import React from 'react';
import './Button.css';

class Button extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitting: false
    }
  }

  render() {
    const { canSubmit, onSubmit, text } = this.props;

    return (
      <button
        className={`submit${!canSubmit ? ' disabled' : ''}`}
        type="submit" disabled={!canSubmit ? true : false}
        onClick={(e) => onSubmit(e)}
      >
        { (text) ? text : 'Submit' }
    </button>
    );
  }
}

export default Button;
