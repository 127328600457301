/**
 * Checkout
 * 
 * @flow
 */
import React from 'react';
import {Helmet} from 'react-helmet';
import {Elements, StripeProvider } from 'react-stripe-elements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImageHeading from '../../../components/image/ImageHeading';
import CheckoutForm from './CheckoutForm';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
import { SHOP_TRAIL, STRIPE_KEY } from "../../../data/Data";
import './Checkout.css';

const IMAGE = 'aikido-maaai-checkout.jpg';

type Props = {};

class Checkout extends React.Component<Props> {
  render() {
    const title="Checkout";
    const description = "Complete your purchases with the secure Stripe payment gateway.";
    return (
      <div className="checkout">
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
        <ImageHeading embed={false} title="Checkout" image={IMAGE} />
        <section>
          <Breadcrumbs {...this.props} trail={SHOP_TRAIL} />
          <StripeProvider apiKey={STRIPE_KEY}>
            <Elements>
              <CheckoutForm {...this.props} />
            </Elements>
          </StripeProvider>
          <div className="notice">
            <FontAwesomeIcon icon="lock" /> Payment is through the secure Stripe payment gateway.
          </div>
        </section>
      </div>
    );
  }
}

export default Checkout ;
