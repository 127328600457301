import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Faq.css';

class Faq extends React.Component {
  constructor(props) {
    super(props);

    this.faqRef = React.createRef();

    let hash = (window.location.hash) ? window.location.hash.substring(1) : null;

    const {title, content } = this.props;
    const id = title.replace(/[^a-z0-9]/gmi, '').replace(/\s+/g, '');
    const show = (id === hash) ? true : false;
    
    this.state = {
      id: id,
      title: title,
      hash: show,
      content: content,
      show: show,
    };
  }

  componentDidMount() {
    if (this.state.hash) {
      window.scrollTo(0, this.faqRef.current.offsetTop);
    }
  }

  toggle = () => {
    this.setState({
      show: !this.state.show
    });
  }

  render() {
    const { id, title, content } = this.state;
    return (
      <article className="faq" id={id} ref={this.faqRef}>
        <div className="heading" onClick={this.toggle}>
          <FontAwesomeIcon icon={(this.state.show) ? "minus" : "plus"} onClick={this.showMenu}/>
          <h2>
            {title}
          </h2>
        </div>
        <div dangerouslySetInnerHTML={{__html: content}} className={`content ${this.state.show ? 'show' : 'hide'}`}/>
      </article>
    );
  }

  
}

export default Faq;
