import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Address.css';

class Address extends React.Component {

  render() {
    const showTitle = this.props.showTitle ? this.props.showTitle : false;
    return (
      <div className="address">
        {showTitle ? <h2>Contact</h2> : null} 
        <div itemScope itemType="http://schema.org/Organization">
          <h3 itemProp="name">Aikido Maai</h3>
          <div itemProp="contactPoint">Sensei Ajax</div>
          <div className="phone">
            <span itemProp="telephone">
              <FontAwesomeIcon icon="mobile-alt" />
              <a href="tel:0404301542">0404 301 542</a>
            </span>
          </div>
          
          <div className="locations">
            <div itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
              <h3>Open handed training location:</h3>
              <span itemProp="streetAddress">Syndal South Primary School,</span>
              <span itemProp="streetAddress">14 Montgomery Ave,</span>
              <span itemProp="postalCode">Mt Waverley,</span>
              <span itemProp="addressLocality">VIC</span>
            </div>
            <div itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
              <h3>Weapons training location:</h3>
              <span itemProp="streetAddress">Ringwood</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Address;