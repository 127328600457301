/**
 * Image
 */

import React from 'react';
import './Image.scss';
const IMAGE_ROOT = 'https://aikidomaai-media.s3.amazonaws.com/';


class Image extends React.Component{
  render() {
    const image = this.props.image;
    return <img src={`${IMAGE_ROOT}${image.src}`} alt={image.alt} className={this.props.className} />;
  }
}

export default Image;


