/**
 * Landing Page
 * 
 * @flow
 */
import React from 'react';
import {Helmet} from 'react-helmet';
import ImageHeading from '../image/ImageHeading';
import InterestForm from '../forms/InterestForm';
import Location from '../location/Location';
import LearnAikidoDotPoints from '../dot-points/LearnAikidoDotPoints';
import { LANDING_PAGES } from './LandingPages.data';
import type BugsnagClient from '@bugsnag/js';
import type LocalForage from 'localforage';
import './LandingPage.css';

type Props = {
  bugsnagClient: BugsnagClient,
  localforage: LocalForage,
  location: {
    pathname: string
  }
};

class LandingPage extends React.Component<Props> {

  render() {
    const {location} = this.props;
    // All Geo urls are formatted like `martial-arts-{suburb}`
    const slug = location.pathname.substr(14);
    const geo = slug.split('-');
    let suburb = '';
    for (let i=0; i<geo.length; i++) {
      suburb += `${geo[i].substr(0,1).toUpperCase()}${geo[i].substr(1)} `;
    }
    suburb = suburb.trim();

    const content = [...LANDING_PAGES[0].content];
    const title = `Martial Arts ${suburb}`;
    const description = `Learn Aikido at Aikido Maai, martial arts training, ${suburb}`;

    return (
      <article className="page landing learn geo">
          <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
          </Helmet>
        <ImageHeading  title={`Martial Arts ${suburb}`} image={LANDING_PAGES[0].image} />
        <section>
          <div className="geo">
            <p><strong>Learn martial arts ({suburb})</strong> with Aikido Maai{suburb !== 'Mt Waverley' ? ', in nearby  Mt Waverley' : ''}. If you are looking to get fit, have fun and learn some practical defence skills, then Aikido is for you. <strong>Claim your free class now</strong>, and try Aikido out for yourself. We run a fun inclusive and safe training space.</p>
          </div>
          <br /><br />
          <LearnAikidoDotPoints {...this.props} />
        </section>
        <ImageHeading image="aikido-sankyo-ura.jpg" title="Modern Ancient" embed={true} />
        <section>
          <div dangerouslySetInnerHTML={{__html: content[1]}} />
          <InterestForm {...this.props} />
        </section>
        <ImageHeading image="learn-aikido-throws.jpg" title="Class formats" embed={true} />
        <section>
          <div dangerouslySetInnerHTML={{__html: content[2]}} />
          <InterestForm {...this.props} />
        </section>
        <ImageHeading image="learn-aikido-weapons.jpg" title="Weapons" embed={true} />        
        <section>
          <div dangerouslySetInnerHTML={{__html: content[3]}} />
          <InterestForm {...this.props} />
        </section>
        <Location embed={true} />
      </article>
    );
  }
}

export default LandingPage;
