/**
 * Upcoming classes
 * @flow
 */
import React from 'react';
import { SCHOOL_TERMS } from '../../pages/classes/Classes.data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import CalendarDate from '../calendar-date/CalendarDate';
import { createDates } from '../../functions/Functions';
import type Moment from 'moment';
import type { Weekday } from '../../types/Types';
import './UpcomingClasses.css';

type Props= {
  days: [{
    day: Weekday,
    dates: Array<Date>,
    startHour: number,
    startMinute: number
  }],
  id: string
};

type State = {
  dates: Array<Moment>,
  show: boolean
};

class ClassesUpcoming extends React.Component<Props, State> {
  static defaultProps = {
    days: [{
      day: 'Tuesday',
      dates: [],
      startHour: 18,
    }],
    kids: false
  };

  constructor(props: Props) {
    super(props);
    const { days, id } = props;
    const isKids = id === 'childrens-aikido' ? true : false;
    let dates = [];
    let weeks = 12;

    const now = moment();
    let startDate = new Date();

    if (isKids) {
      for (let i = 0; i < SCHOOL_TERMS.length; i++) {
        const { end, start } = SCHOOL_TERMS[i];
        startDate = moment(new Date(start));

        if (now.isBefore(startDate)) {
          const endDate = moment(new Date(end));
          weeks = endDate.diff(startDate, 'weeks');
          startDate = startDate.toDate();
          break;
        }
      }
    }
    
    for ( let i = 0; i < days.length; i++) {
      const { day, startHour, startMinute } = days[i];
      console.warn('ClassesUpcoming startHour', startHour)
      const daysDates = createDates(day, startDate, startHour, startMinute, weeks);
      dates = [ ...dates, ...daysDates];
    }

    dates.sort(function(a,b) {
      return moment(a).isBefore(b) ? -1 : moment(a).isAfter(b) ? 1 : 0;
    });
    
    this.state = {
      dates,
      show: false,
    };
  }

  show = () => {
    this.setState({
      show: !this.state.show
    });
  }

  render() {
    const { id } = this.props;
    const { dates, show } = this.state;

    return (
      <li className={`ClassesUpcoming ${id}`}>
        <FontAwesomeIcon icon="calendar-check" />
        <div
          className={`title${show ? ' open': ''}`}
          onClick={this.show}
          tabIndex="0"
          aria-controls="next-classes"
          expanded={show ? 'true' : 'false'}
          title={`${show ? 'Close' : 'Open'} upcoming class dates`}
        >
          <span className="text">Upcoming classes <span className="number">{dates.length.toFixed(0)}</span></span>
          <FontAwesomeIcon icon={show ? 'chevron-up' : 'chevron-down'} />
        </div>
        <div className={`next-classes${show ? ' show' : ''}`}>
          {
            dates.map((date, index) => {
              return (
                <CalendarDate key={index} date={date} />
              )
            })
          }
        </div>
      </li>
    );
  }
}

export default ClassesUpcoming;
