import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Pagination.css';

const LIMIT = 10;

class Pagination extends React.Component {

  listItem = (i) => {
    const { page, current }  = this.props;
    const x = i + 1;
    if (current === x + 1) {
    return <li key={x} className="current">{x}</li>;
  
    }
    return <li key={x} onClick={() => page(i)}>{x}</li>;
  }

  render() {
    const { items, current} = this.props;
    if (items.length === 0) return null;

    let paginate = [];
    if (items.length > LIMIT) {
      const pages = Math.ceil(items.length / LIMIT);

      switch(true) {
        case(pages < 5):
          for (let i=0; i<pages; i++) {
            paginate.push(this.listItem(i));
          }
          break;

        case(pages >= 5):
          paginate.push(
            <li key="previous" className="previous">
              <FontAwesomeIcon icon="search" className="chevron-left" onClick={this.search}/>
            </li>
          );

          for (let i=current; i<(current + 2); i++) {
            paginate.push(this.listItem(i));
          }

          paginate.push(
            <li key="dots" className="dots">
              <FontAwesomeIcon icon="search" className="ellipsis-h" onClick={this.search}/>
            </li>
          );

          paginate.push(this.listItem(pages - 1, pages));

          paginate.push(
            <li key="next" className="next">
              <FontAwesomeIcon icon="search" className="chevron-right" onClick={this.search}/>
            </li>
          );
          break;

        default:
          // no op
            
      }

      return (
        <ul className="pagination">
          {paginate}
        </ul>
      );
    }

    return null;
  }
}

export default Pagination;
