/**
 * Select dropdown
 * @flow
 */
import React from 'react';
import type { Option } from '../../types/Types';
import './Select.css';

type Props = {
  label: string,
  name: string,
  options: Array<Option>,
  required: boolean,
  setValues: (name: string, value: string, valid: boolean) => *,
  value: string,
};

class Select extends React.Component<Props> {
  static defaultProps = {
    label: 'Select',
    name: 'select',
    options: [],
    required: false,
    value: '',
  }; 

  /**
   * onChange
   * 
   * @param object e the synthetic event
   */
  onChange = (e: SyntheticMouseEvent<>) => {
    const { name, setValues } = this.props;
    // $FlowFixMe
    const { value } = e.target
    const valid = value !== '' ? true : false;
    // console.warn(name, value, valid);
    setValues(name, value, valid);
  }

  /**
   * Render options
   */
  renderOptions = () => {
    const { options } = this.props;

    let select = [];
    for (let i=0; i<options.length; i++) {
      const { value } = options[i];
      
      select.push(
        <option key={i}>
          {value}
        </option>
      );
    }
    return select;
  }

  /**
   * get selected value
   */
  getSelected = () => {
    const { options, value } = this.props;
    let selected = options[0].value;

    for (let i=0; i<options.length; i++) {
      if (options[i].value === value){
        selected = options[i].value;
      }
    }

    return selected;
  }

  render() {
    const { label, required } = this.props;
    const value = this.getSelected();

    return (
      <div className="field select">
        <div className="label">
          {label}
          {required ? <span className="required">*</span> : null}
        </div>
        <select value={value} onChange={this.onChange}>
          {this.renderOptions()}
        </select>
      </div>
    );
  }
}

export default Select;
