import React from 'react';
import {injectStripe } from 'react-stripe-elements';
import CheckoutFormFields from './CheckoutFormFields';

class CheckoutForm extends React.Component {
   render() {
    return (
      <CheckoutFormFields {...this.props} />
    )
  }
}

export default injectStripe(CheckoutForm) ;
