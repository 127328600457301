import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Success.css';

class Success extends React.Component {
  render() {
    const { success, form } = this.props;
    if (!form || !form.success ) {
      return null;
    }
    const message  = form.success.message;
    if (success) {
      return (
        <div className="success">
          <FontAwesomeIcon icon="check" className="check" />
          <span className="text">
            {message ? message : 'Thanks for your message.'}
          </span>
        </div>
      );
    }
    return null;
  }
}

export default Success;