/**
 * Home Feature
 * @flow
 */
import React from 'react';
import {Link} from 'react-router-dom';
import { FEATURE, IMAGE_ROOT } from '../../data/Data';
import './HomeFeature.css';

const HomeFeature = () => {
  const { text } = FEATURE;
  return (
    <div className="HomeFeature">
      <Link to="/classes">
      <img src={`${IMAGE_ROOT}aikido-maai-location.jpg`} alt={FEATURE.text}/>
        <h1>{text}</h1>
      </Link>
      <p>Aikido Maai is closed. Thanks for training with us.</p>
     
    </div>
  );
}

export default HomeFeature;