export const FAQ_IMAGE = 'aikido-faqs.jpg';

export const FAQS = [
  {
    title: 'What does “Aikido” mean?',
    content: '<p>Aikido is the art of peace. The english translation equates to:</p><ul><li>Ai – harmony/universe</li><li>Ki – energy</li><li>Do – way</li></ul><p>Or, the way of harmonising energy. In practice it is a physical, mental and philosophical training framework to be our best selves, and create more harmony in our lives, and the lives of those that we touch.</p>',
  },
  {
    title: 'Why should I train Aikido?',
    content: '<p>When training Aikido our aim is not to defeat an opponent, but to defeat ourselves. if you reflect upon&nbsp;how you interact with coworkers, family and friends on a daily basis, &nbsp;you may find that many of the conflicts that you are experiencing are due to internal conflicts you are feeling within yourself. We subconsciously project these inner conflicts out onto other people, and then attack the “other” when really it is ourselves with whom we are not at peace.</p><p>Aikido provides a physical, mental and philosophical framework through which we can work upon ourselves, in dynamic situations, and learn constructive ways to resolve conflict to both people’s advantage.</p><p>If you&nbsp;would like to learn how to resolve conflict more constructively, then training Aikido is for you.</p>',
  },
  {
    title: 'Is Aikido an effective martial art?',
    content: `<p>To answer this question we need to define what we mean by "effective". If you mean "effective" as in "is Aikido effective in the octagon against MMA fighters?" Then the answer would be "no". If you want to learn to fight MMA fighters then training MMA would be a better option for you. </p>
    <p>If you mean "effective" as in "Will I be able to defend my self against an attacker on the street, or in my home, and prevent myself from being raped or beaten up?", then the answer would be "yes".</p>
    <p>Ryron Gracie, grandson of the founder of Brazilian Jui Jitsu states that <a href="https://www.youtube.com/watch?v=ZM_8-c1EqOY#t=26" target="_blank">99% of street fights can be avoided</a>. So of the 1% of fights you can't avoid - someone being attacked and you have to step in a do something, or you are being attacked, how many of these will be against a black belt from another martial art? Very few people gain black belts (you will though, right?). So I would say the chances of having to fight a trained martial artist on the street are, at best, 1 in 1000, and probably more like 1 in 100,000. Most martial artists I know, regardless of style, are nice people, who aren't training to learn to beat people up. So your chances of having to fight another martial artist on the street is pretty slim.</p>

    <p>Now, have you watched a BJJ, MMA, or boxing match with two highly trained fighters against each other? How long does it take for someone to win? It can take several rounds, which last minutes to tens of minutes. So what's the the difference between senior BJJ guys? It's marginal. They can roll for 10 minutes and neither of them will get the other to tap out. What's the difference between two senior karate people fighting? It's marginal. Again, it can take 10 or more minutes, and still neither of them will win. What's the difference between two senior MMA fighters? Again, it's marginal. And the difference between two senior Aikidoka? Yep, it's marginal.</p>
    
    <p>Yet, every single one of these trained martial artists against an untrained attacker? They would dominate them in seconds. Now the difference between the Aikido trained person and all the others, is that when an Aikido trained person is attacked - they seek to use the least force (and effort) possible to resolve the situation. Other martial arts teach maximum damage. This can mean that if you hospitalise your attacker, even though you were defending yourself, you can potentially be charged with assault.</p>

    <p>So the answer to is Aikido "effective" depends on your terms of reference and what you want to be effective at. If we frame the question as "Is aikido an effective tool to improve myself and live my best life?" then the answer is a definite "yes". Aikido provides a well developed and nuanced philosphical framework to learn to resolve conflict wihin ourselves and around us while using minimal force and effort. This philosophy is practiced and expressed through training exercises and techniques. The principals of getting off the line, positioning yourself where it's difficult for the attacker to strike you, and destabilising and controlling your attacker provide metaphors for handling verbal altercations as well as managing physical violence.</p>
    
    <p>So, I believe that Aikido is an effective martial art. The only way for you to find out if it is effective for you is to train with an open mind and spend enough time learning it to be able to make an informed decision.</p>
    `,
  },
  {
    title: 'How long will it take me to become a black belt?',
    content: '<p>If you turn up to every class in every week, then you will attain a black belt within 3–4 years. However, please understand that shodan (1st degree black belt) means that you now understand the basics of the art! This is not an end point, only a milestone in your personal journey. Once you have your black belt you can really begin training. And work towards your nidan (2nd degree black belt).</p>',
  },
  {
    title: 'Do I need to be strong to train Aikido?',
    content: '<p>Aikido deflects and manipulates the force of the attacker. We blend with incoming attacks and apply appropriate force to reciprocate a defensive technique to neutralise the threat.</p><p>So, no, you don’t need strength to be an effective Aikidoka. The correct angles and application of technique to vital parts of your oppenents body will produce a maximal result.</p>',
  },
  {
    title: 'Can I train just one day a week?',
    content: '<p>If you want to achieve your goals, and attain the attributes that you started Aikido&nbsp;for, like discipline, focus, centredness, learning self defence, then you need to turn up as regularly as you possibly can. As such the fees for junior students are structured so that you are motivated to turn up. All fees are due via subscription through this website, and are payable on the 1st of the month.</p>',
  },
  {
    title: 'How much should I train?',
    content: '<p>You should train as much as you possibly can. Turning up to to training two nights a week will allow you to train 3-4 classes. The more you train, the more benefits you get.</p>',
  },
  {
    title: 'Do I need to train weapons?',
    content: '<p>You definitely need to learn Aikido weapons. The direct technical lineage of Aikido open handed technique comes from sword and staff. We train ken (wooden sword) and jo (wooden staff) &nbsp;on Wednesdays at 6.10pm.</p>',
  },
  {
    title: 'Why do we bow in at the beginning of class, and out at the end?',
    content: '<p>We bow in because it is traditional and respectful to bow to your training partners before training. We start by bowing to the shomen and clapping two times. This is from traditional shinto practice. First we bow to the universe, then students bow to sensei, and then before training with a new partner we bow to each other. The process is repeated at the end of the class to bow out.</p>',
  },
];