/**
 * Form
 * 
 * @flow
 */
import React from 'react';
import TextInput from '../../components/textinput/TextInput';
import Textarea from '../../components/textarea/Textarea';
import RadioButtons from '../../components/radiobuttons/RadionButtons';
import Select from '../../components/select/Select';
import Honeypot from '../../components/honeypot/Honeypot';
import Button from '../../components/button/Button';
import Error from '../../components/error/Error';
import Submitting from '../../components/submitting/Submitting';
import Success from '../../components/success/Success';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fn from '../../functions/Functions';
import { Link, Redirect } from 'react-router-dom';
import type { FormType } from '../../types/Types';
import type BugsnagClient from '@bugsnag/js';
import moment from 'moment';
import { CHECKIN } from './CheckIn.data';
import { PRODUCTS } from '../shop/Products.data';
import type { Mutate } from '../../types/Types';
import './CheckinForm.css';

const RANDOM_ID = (Math.random() * 1000).toFixed(0);

type Props = {
  form: FormType,
  jest: boolean,
  bugsnagClient: BugsnagClient,
  mutateBasket: (mutate: Mutate) => *,
};

type State = {
  canSubmit: boolean,
  destination: string,
  error: boolean,
  errorMessage: string,
  form: FormType,
  isKids: boolean,
  redirect: boolean,
  submitting: boolean,
  success: boolean,
};

class Form extends React.Component<Props, State> {
  static defaultProps = {
    form: CHECKIN,
    jest: false,
  };

  constructor(props: Props) {
    super(props);
    
    this.state = {
      canSubmit: false,
      destination: '/checkout',
      error: false,
      errorMessage: 'Sorry, there has been an error submitting the form.',
      form: props.form,
      isKids: false,
      redirect: false,
      submitting: false,
      success: false,
    }

    // $FlowFixMe
    this.generateMessages = this.generateMessages.bind(this);
  }

  componentDidMount() {
    setTimeout(this.formFilled, 2000);
  }

  /**
   * Values passed up from input
   */
  setValues = (name: string, value: string, valid: boolean) => {
    let { form, isKids }  = this.state;
    let { inputs } = form;

    for (let i=0; i<inputs.length; i++) {
      if (name === inputs[i].name) {
        inputs[i].value = value;
        inputs[i].valid = valid;
      }
    }

    // Check in class had "Kid" in the name
    if (inputs[3].value.match(/Kid/)) {
      isKids = true;
      inputs[1].required = true;
    } else {
      isKids = false;
      inputs[1].required = false;
    }

    this.setState({
      form: {
        ...form,
        inputs,
      },
      isKids,
    }, () => this.formFilled());
  }

  /**
   * Check if the form is filled
   */
  formFilled = () => {
    const { form } = this.state;
    if (!form) return null;

    const inputs = [...form.inputs];
    let isRequired = 0;
    let isValid = 0;

    for (let i=0; i<inputs.length; i++) {
      const input = inputs[i];

      if (input.required){
        ++isRequired;
      }

      if (input.valid){
        ++isValid;
      }
    }

    if (isValid >= isRequired) {
      this.canSubmit(true);
    } else {
      this.canSubmit(false);
    }
  }

  /**
   * Can we submit?
   */
  canSubmit = (canSubmit: boolean) => {
    this.setState({
      canSubmit: canSubmit
    });
  }

  /**
   * onSubmit
   */
  onSubmit = (e: SyntheticEvent<>) => {
    e.preventDefault();
    this.setState({
      submitting: true,
    });

    this.generateMessages();
  }

  /**
   * Generate SMS confirmations
   */
  async generateMessages() {
    const { isKids } = this.state;
    const msg = this.ownerConfSlack();
    const {pay} = this.extractData();

    if (msg) {
      this.notifySlack(msg);
      this.setState({
        submitting: false,
        success: true ,
      });

      switch(true) {
        case(pay === 'Online' && isKids):
          this.setState({
            destination: '/shop'
          });
          setTimeout(this.redirect, 2000);
        
        break;


        case(pay === 'Online'): {
          const action = {
            action: 'add',
            product: PRODUCTS[0]
          };
          this.props.mutateBasket(action);
          setTimeout(this.redirect, 2000);
        }
        break;

        default:
          // no op
      }
    }
  }

  notifySlack = (message: {text: string}) => {
    // Don't send if this is a Jest test
    const {jest, bugsnagClient} = this.props;
    if (jest) {
      return;
    }
    fn.notifySlack(message, bugsnagClient, 'checkin');
  }

  /**
   * Owner conf slack message
   */
  ownerConfSlack = () => {
    const { checkInClass, childName, name, mobile, pay } = this.extractData();
    if (checkInClass && name && mobile && pay) {
      return {
        text: `${name} (${mobile}) checked${childName ? ` ${childName}` : ''} in to ${checkInClass}. Payment status: ${pay} `
      };
    }
    return false;
  }

  /**
   * Check if the mobile is Australian
   */
  isMobileOz = (mobile: string) => {
    const re = /(\+61)*\s*(0)*(4|5)[0-9\s]{8,10}/;
    return re.test(mobile);
  }

  /**
   * Extract data from the inputs
   * 
   * @returns {object} name, email, mobile, message
   */
  extractData = () => {
    const { form } = this.state;

    let checkInClass = '';
    let name = '';
    let childName = '';
    let email = '';
    let mobile = '';
    let pay = '';
    const inputs = form.inputs;
    for (let i=0;  i<inputs.length; i++) {
      const input = inputs[i];

      switch (input.name) {
        case ('checkInClass'):
          checkInClass = input.value;
          break;

        case('name'):
          name = input.value;
          break;

        case('childName'):
          childName = input.value;
          break;

        case('mobile'):
          mobile = input.value;
          break;

        case('pay'):
          pay = input.value ;
          break;

        default:
          // no op
      }
    }

    return {checkInClass, childName, name, email, mobile, pay};
  }

  /**
   * redirect
   */
  redirect = () => {
    this.setState({
      redirect: true
    });
  }

  /**
   * Error 
   * @params object - error ye olde error
   */
  error = (error: Error) => {
    this.setState({
      error: true,
      errorMessage: 'Error',
      success: false,
      submitting: false,
    });
  }

  render() {
    const {canSubmit, destination, error, form, isKids, redirect, submitting, success} = this.state;
    const { name, pay } = this.extractData();

    if (redirect) {
      return <Redirect to={destination} />
    }

    if (!form) return null;

    let parts = [];
    const inputs = form.inputs;

    for (let i=0; i<inputs.length; i++) {
      const input = inputs[i];
      switch(input.type) {
        case('text'):
        case('email'):
        case('password'):
        case('phone'):
          parts.push(<TextInput key={i} {...this.props} form={form} {...input} setValues={this.setValues} />);
          break;

        case('textarea'):
          parts.push(<Textarea key={i} {...this.props} form={form} {...input} setValues={this.setValues} />);
          break;

        case('radio'):
          parts.push(<RadioButtons key={i} {...this.props} form={form} {...input} setValues={this.setValues} />);
          break;

        case('select'):
          parts.push(<Select key={i} {...this.props} form={form} {...input} setValues={this.setValues} />);
          break;

        default:
          // no op
      }      
    }
 
    return (
      <div className={`form-container ${form.className}${isKids ? ' kids': ''}`}>
        <form
          id={`${form.id ? `${form.id}-${RANDOM_ID}` : `form-${RANDOM_ID}`}`}
          className={`form${(submitting || success || error) ? ' hidden' : ''}`} >
          <h2>{form.title}</h2>
          {parts}
          <Honeypot {...this.props} canSubmit={this.canSubmit} />
          <Button
            {...this.props}
            canSubmit={canSubmit}
            onSubmit={this.onSubmit}
            submitting={submitting} />
        </form>
        <Error {...this.state} />
        <Submitting submitting={submitting} />
        <Success {...this.props} success={success} />
        {
          success && pay === "Online"
          
            ? <div className="pay online">
                <FontAwesomeIcon icon="spinner" spin={true} />
                <Link className="button" to="/checkout">
                  Pay now
                </Link>
              </div>
            : null
        }
        {
          success && pay === "Bank Transfer"
            ? (
                <div className="pay bank">
                  <p>Please transfer $20 to the following account:</p>
                  <ul>
                    <li><strong>BSB:</strong> 063124</li>
                    <li><strong>Account:</strong> 10671897</li>
                    <li><strong>Reference:</strong> {name} {moment().format('D MM')}</li>
                  </ul>
                </div>)
            : null
        }
        {
          success && pay === "Cash"
            ? (
                <div className="pay cash">
                  <p>Please give Sensei Ajax $20</p>
                </div>)
            : null
        }
      </div>
    );
  }
}

export default Form;
