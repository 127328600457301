import { CLASSES_IMAGE, CLASSES_CONTENT} from '../../pages/classes/Classes.data';
import { PAGES } from '../page/Pages.data';
import { SHOP_IMAGE } from '../../pages/shop/Products.data';
import { LANDING_PAGES } from '../landing-pages/LandingPages.data';
import { FAQ_IMAGE } from '../../pages/faq/Faq.data';

export const NAV = [
  {
    path: '/classes',
    title: 'Classes',
    image: CLASSES_IMAGE,
    content: CLASSES_CONTENT,
    subMenu: [
      {
        path: '/classes/checkin',
        title: 'Check In',
        content: 'Check in to class.'
      },
    ]
  },
  {
    path: '/learn-aikido',
    title: 'Learn',
    content: "Be your best self! Learn Aikido",
    image: LANDING_PAGES[0].image,
  },
  {
    path: '/childrens-aikido-classes',
    title: "Kids",
    content: "Kid's aikido classes",
    image: LANDING_PAGES[1].image,
  },
  {
    path: '/shop',
    title: 'Shop',
    image: SHOP_IMAGE,
    content: 'Pay for classes, purchase training uniforms and weapons'
  },
  {
    path: '/what-is-aikido',
    title: 'What is Aikido?',
    image: PAGES[2].image,
    content: PAGES[2].content,
  },
  {
    path: '/students',
    title: 'Students',
    image: CLASSES_IMAGE,
    content: CLASSES_CONTENT,
    subMenu: [
      {
        path: '/aikido-maai-training-manual/dojo-etiquette',
        title: 'Dojo Etiquette',
        content: 'How to train respectfully, and get the most out of your training.'
      },
      {
        path: '/aikido-maai-training-manual/terms-conditions-training',
        title: 'Terms of training',
        content: 'The term and conditions of training.',
      },
      {
        path: '/gradings',
        title: 'Gradings',
        image: CLASSES_IMAGE,
        content: CLASSES_CONTENT,
        subMenu: [
          {
            path: '/gradings/shodan',
            title: 'Shodan grading',
          },
          {
            path: '/gradings/kyu-ranks',
            title: 'Kyu gradings',
          },
          {
            path: '/gradings/kids',
            title: 'Kids gradings',
          },
        ]
      },
    ],
  },
  {
    path: '/about',
    title: 'About',
    image: 'aikido-maaai-about.jpg',
    content: 'About Aikido Maai',
    subMenu: [
      {
        path: '/about/location',
        image: 'aikido-maai-location.jpg',
        title: 'Location',
        content: 'The dojo is located at 14 Montgomery Ave, Mt Waverley, VIC, 3194',
      },
      {
        path: '/about/lineage',
        image: 'aikido-maai-ajax-receives-4th-dan.jpg',
        title: 'Lineage',
        content: 'The lineage of the dojo.'
      },
      {
        path: '/about/faq',
        image: FAQ_IMAGE,
        title: 'FAQ',
        content: 'So many questions! Each one with an answer.'
      },
      {
        path: '/about/contact',
        image: 'aikido-maai-contact.jpg',
        title: 'Contact',
        content: 'Contact the dojo.'
      },
    ]
  },
  {
    path: '/search',
    title: 'Search',
  },
];