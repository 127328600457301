/**
 * Learn Aikido Landing Page
 * @flow
 */
import React from 'react';
import {Helmet} from 'react-helmet';
import ImageHeading from '../image/ImageHeading';
import InterestForm from '../forms/InterestForm';
import LearnAikidoDotPoints from '../dot-points/LearnAikidoDotPoints';
import { LANDING_PAGES } from './LandingPages.data';
import type BugsnagClient from '@bugsnag/js';
import type LocalForage from 'localforage';
import './LandingPage.css';

type Props = {
  bugsnagClient: BugsnagClient,
  jest: boolean,
  localforage: LocalForage,
  location: {
    pathname: string
  }
};

class LandingPage extends React.Component<Props> {
  static defaultProps = {
    jest: false,
  };

  render() {
    const content = [...LANDING_PAGES[0].content];
    const title = "Learn Aikido";
    const description = "Learn Aikido, learn martial arts training";
    return (
      <article className="page landing learn">
          <Helmet>
            <title></title>
            <meta name="description" content={description} />
          </Helmet>
        <ImageHeading  title="Learn Aikido" image={LANDING_PAGES[0].image} />
        <section>
          <LearnAikidoDotPoints {...this.props} />
        </section>
        <ImageHeading image="aikido-sankyo-ura.jpg" title="Modern Ancient" embed={true} />
        <section>
          <div dangerouslySetInnerHTML={{__html: content[1]}} />
          <InterestForm {...this.props} />
        </section>
        <ImageHeading image="learn-aikido-throws.jpg" title="Class formats" embed={true} />
        <section>
          <div dangerouslySetInnerHTML={{__html: content[2]}} />
          <InterestForm {...this.props} />
        </section>
        <ImageHeading image="learn-aikido-weapons.jpg" title="Weapons" embed={true} />        
        <section>
          <div dangerouslySetInnerHTML={{__html: content[3]}} />
          <InterestForm {...this.props} />
        </section>
      </article>
    );
  }
}

export default LandingPage;