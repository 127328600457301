/**
 * Form
 * 
 * @flow
 */
import React from 'react';
import TextInput from '../textinput/TextInput';
import Textarea from '../textarea/Textarea';
import RadioButtons from '../radiobuttons/RadionButtons';
import Honeypot from '../honeypot/Honeypot';
import Button from '../button/Button';
import Error from '../error/Error';
import Submitting from '../submitting/Submitting';
import Success from '../success/Success';
import fn from '../../functions/Functions';
import type {FormType} from '../../types/Types';
import type BugsnagClient from '@bugsnag/js';
import {ADMIN_NAME, ADMIN_EMAIL,} from '../../data/Data';
import {DEFAULT_FORM} from './Form.data';
import './Form.css';

const MSG_LENGTH = 100;

type Props = {
  form: FormType,
  jest: boolean,
  bugsnagClient: BugsnagClient,
};

type State = {
  error: boolean,
  errorMessage: string,
  form: FormType,
  canSubmit: boolean,
  submitting: boolean,
  success: boolean,
};

class Form extends React.Component<Props, State> {
  static defaultProps = {
    form: DEFAULT_FORM,
    jest: false,
  };

  constructor(props: Props) {
    super(props);
    
    this.state = {
      error: false,
      errorMessage: 'Sorry, there has been an error submitting the form.',
      form: props.form,
      canSubmit: false,
      submitting: false,
      success: false,
    }

    // $FlowFixMe
    this.generateMessages = this.generateMessages.bind(this);
  }

  /**
   * Values passed up from input
   */
  setValues = (name: string, value: string, valid: boolean) => {
    let  form  = { ...this.state.form };
    let { inputs } = form;

    for (var i=0; i<inputs.length; i++) {
      if (name === inputs[i].name) {
        inputs[i].value = value;
        inputs[i].valid = valid;
      }
    }

    this.setState({
      form: {
        ...form,
        inputs
      }
    }, () => this.formFilled());
  }

  /**
   * Check if the form is filled
   */
  formFilled = () => {
    const { form } = this.state;
    if (!form) return null;

    const inputs = [...form.inputs];
    let isRequired = 0;
    let isValid = 0;

    for (let i=0; i<inputs.length; i++) {
      const input = inputs[i];

      if (input.required){
        ++isRequired;
      }

      if (input.valid){
        ++isValid;
      }
    }

    if (isValid >= isRequired) {
      this.canSubmit(true);
    } else {
      this.canSubmit(false);
    }
  }

  /**
   * Can we submit?
   */
  canSubmit = (canSubmit: boolean) => {
    this.setState({
      canSubmit: canSubmit
    });
  
  }

  /**
   * onSubmit
   */
  onSubmit = (e: SyntheticEvent<>) => {
    e.preventDefault();
    this.setState({
      submitting: true,
    });

    this.generateMessages();
  }

  /**
   * Generate SMS confirmations
   */
  async generateMessages() {
    const {bugsnagClient, jest} = this.props;
    const conf = [
      {
        type: 'slack',
        data: this.ownerConfSlack()
      },
      // {
      //   type: 'sms',
      //   data: this.senderConfSMS()
      // },
      {
        type: 'email',
        data: this.senderConfEmail()
      },
    ];

    let success = 0;

    for (let i=0; i<conf.length; i++) {
      const msg = conf[i];
      if (!msg.data) return;
      if ('slack' === msg.type) {
        this.notifySlack(msg.data);
        success++;
      } else {
        // Don't send if this is a Jest test
        if (jest) {
          return;
        }

        const sent = await fn.sendMessage(msg.type, msg.data, bugsnagClient);
        if (sent) {
          success++;
        }
      }
    }

    this.setState({
      submitting: false,
      success: success === conf.length ? true : false,
    });
  }

  notifySlack = (message: {text: string}) => {
    // Don't send if this is a Jest test
    if (this.props.jest) {
      return;
    }

    fn.notifySlack(message, this.props.bugsnagClient);
  }

  /**
   * Owner conf slack message
   */
  ownerConfSlack = () => {
    const { name, mobile, message } = this.extractData();
    if (name && mobile && message) {
      return {
        text: `Call ${name} on ${mobile} about '${message}'`
      };
    }
    return false;
  }

  /**
   * Owner conf email
   */
  ownerConfEmail = () => {
    const { email, name, mobile, message } = this.extractData();
    if (email && name && mobile && message) {
      return {
        from: {
          name,
          email
        },
        message: `Call ${name} on ${mobile} about '${message}'`,
        subject: 'New submission from contact form',
        to: {
          email: "admin@aikidomaai.com",
          name: 'Ajax',
        }
      };
    }
    return false;
  }

  /**
   * Sender conf message
   * only send if australian mobile number
   * 
   * @returns {object | boolean} message object or false
   */
  senderConfSMS = () => {
    let { name, mobile } = this.extractData();

    if (name && mobile && this.isMobileOz(mobile)) {
      // Remove spaces from mobile
      mobile = mobile.replace(' ', '');
      return {
        message: `Thanks for your message, ${name}. We'll be in touch soon, Aikido Maai.`,
        to: mobile,
      };
    }
    return false;
  }

  /**
   * Sender conf email
   */
  senderConfEmail = () => {
    const { email, name, message } = this.extractData();
    if (email && name && message) {
      const names = name.split(' ');
      const firstName = names[0] ? names[0] : name.value;

      const messageHtml = `<p>Hi ${firstName},</p><p>Thanks for your message. We've got it and will be in touch soon</p>`;
      return {
        from: {
          email: ADMIN_EMAIL,
          name: ADMIN_NAME,
        },
        messageHtml,
        message: `Hi ${firstName}${"\r\n"}Thanks for your message. We've got it and will be in touch soon`,
        subject: 'Thanks for contacting Aikido Maai',
        to: {
          email,
          name,
        }
      };
    }
    return false;
  }

  /**
   * Check if the mobile is Australian
   */
  isMobileOz = (mobile: string) => {
    const re = /(\+61)*\s*(0)*(4|5)[0-9\s]{8,10}/;
    return re.test(mobile);
  }

  /**
   * Extract data from the inputs
   * 
   * @returns {object} name, email, mobile, message
   */
  extractData = () => {
    const { form } = this.state;

    let name = '';
    let email = '';
    let mobile = '';
    let message = '';
    const inputs = form.inputs;
    for (let i=0;  i<inputs.length; i++) {
      const input = inputs[i];

      switch (true) {
        case( input.name === 'name' ):
          name = input.value;
          break;
        
        case( input.name === 'email' ):
          email = input.value;
          break;

        case( input.name === 'mobile' ):
          mobile = input.value;
          break;

        case( input.name === 'message' ):
          message = (message.length > MSG_LENGTH) ? `${input.value.substring(0, 100)}...` : input.value ;
          break;

        default:
          // no op
      }
    }

    return {name, email, mobile, message};
  }

  /**
   * Success
   */
  success = () => {
    this.setState({
      success: true,
      submitting: false,
    });
  }

  /**
   * Error 
   * @params object - error ye olde error
   */
  error = (error: Error) => {
    this.setState({
      error: true,
      errorMessage: 'Error',
      success: false,
      submitting: false,
    });
  }

  render() {
    const {error, form, submitting, success} = this.state;

    if (!form) return null;

    let parts = [];
    const inputs = form.inputs;
    for (let i=0; i<inputs.length; i++) {
      const input = inputs[i];
      switch(input.type) {
        case('text'):
        case('email'):
        case('password'):
        case('phone'):
          parts.push(<TextInput key={i} {...this.props} form={form} {...input} setValues={this.setValues} />);
          break;

        case('textarea'):
          parts.push(<Textarea key={i} {...this.props} form={form} {...input} setValues={this.setValues} />);
          break;

        case('radio'):
          parts.push(<RadioButtons key={i} {...this.props} form={form} {...input} setValues={this.setValues} />)
          break;

        default:
          // no op
      }      
    }

    const randomID = (Math.random() * 1000).toFixed(0);
    
    return (
      <div className={`form-container ${form.className}`}>
        <form
          id={`${form.id ? `${form.id}-${randomID}` : `form-${randomID}`}`}
          className={`form${(submitting || success || error) ? ' hidden' : ''}`} >
          <h2>{form.title ? form.title : 'Form'}</h2>
          {parts}
          <Honeypot {...this.props} canSubmit={this.canSubmit} />
          <Button
            {...this.props}
            canSubmit={this.state.canSubmit}
            onSubmit={this.onSubmit}
            submitting={this.state.submitting} />
        </form>
        <Error {...this.state} />
        <Submitting submitting={submitting} />
        <Success {...this.props} success={success} />
      </div>
    );
  }
}

export default Form;
