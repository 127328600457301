/**
 * Interest Form
 * 
 * @flow
 */
import React from 'react';
// import Form from './Form';
// import { INTEREST_FORM } from './InterestForm.data';
import type BugsnagClient from '@bugsnag/js';
import './InterestForm.css';

type Props = {
  jest: boolean,
  bugsnagClient: BugsnagClient
};

type State = {
  show: boolean,
};

class InterestForm extends React.Component<Props, State> {
  static defaultProps = {
    jest: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      show: false
    }
  }

  showForm = () => {
    this.setState({
      show: !this.state.show
    })
  }

  render() {
    return null;
    // return (
    //   <div className="interest-form">
    //     <div className="button-container claim">
    //       <div onClick={() => this.showForm()} className={`button${ this.state.show ? ' hide' : ''}`}>Claim your free class</div>
    //     </div>
    //     <div className={`toggle${ this.state.show ? ' show' : ''}`}>
    //       <Form {...this.props} form={INTEREST_FORM} />
    //     </div>
    //   </div>
    // );
  }
}

export default InterestForm;
