// const ONE_YEAR = 'Minimum of 120 classes from last test or 12 months';
const SIXTY_CLASSES = 'Minimum of 60 classes from last test - 6 months';
const THIRTY_CLASSES = 'Minimum of 30 classes or 3 months';

export const SHODAN = [
  {
    title: 'ta na henko',
    attacks: ['Katate dori'],
    variations: ['Kihon', 'ki no nagare']
  },
  {
    title: 'Ikkyo',
    attacks: ['Shomen uchi', 'yokomen uchi', 'katate dori'],
    variations: ['Omote', 'ura', 'tachi', 'suwari'],
  },
  {
    title: 'Nikkyo',
    attacks: ['Shomen uchi', 'yokomen uchi', 'katate dori'],
    variations: ['Omote', 'ura', 'tachi', 'suwari'],
  },
  {
    title: 'Sankyo',
    attacks: ['Shomen uchi', 'yokomen uchi', 'katate dori'],
    variations: ['Omote', 'ura', 'tachi', 'suwari'],
  },
  {
    title: 'Yonkyo',
    attacks: ['Shomen uchi', 'yokomen uchi', 'katate dori'],
    variations: ['Omote', 'ura', 'tachi', 'suwari'],
  },
  {
    title: 'Gokyo',
    attacks: ['Shomen uchi', 'yokomen uchi', 'katate dori'],
    variations: ['Ura','tachi', 'suwari'],
  },
  {
    title: 'Rokkyo',
    attacks: ['Shomen uchi', 'yokomen uchi', 'katate dori'],
    variations: ['Omote', 'ura', 'tachi', 'suwari'],
  },
  {
    title: 'Kokyu ho',
    attacks: ['Morote dori kihon', 'lifting up', 'pulling down', 'wrist held', 'ura'],
  },
  {
    title: 'Irime Nage',
    attacks: ['Shomen uchi', 'yokomen uchi', 'katate dori'],
  },
  {
    title: 'Kubi Nage',
    attacks: ['Shomen uchi', 'yokomen uchi', 'katate dori'],
  },
  {
    title: 'Kote Gaeshi',
    attacks: ['Muna dori', 'yokomen uchi', 'katate dori'],
  },
  {
    title: 'Shiho nage',
    attacks: ['Katate dori', 'shomen uchi',  'yokomen uchi'],
    variations: ['Omote', 'ura']
  },
  {
    title: 'Kaiten nage',
    attacks: ['Shomen uchi',  'tsuki'],
    variations: ['Uchi muwari','Soto muwari']
  },
  {
    title: 'Juuji garami',
    attacks: ['Muna dori', 'ushiro ryote dori', 'ushiro kubi jime'],
  },
  {
    title: 'Tenchi nage',
    attacks: ['Ryote dori'],
    variations: ['Omote', 'Ura'],
  },
  {
    title: 'Koshi nage',
    attacks: ['Katate dori', 'shomen uchi',  'yokomen uchi'],
  },
  {
    title: 'Kokyu nage',
    attacks: ['Katate dori', 'shomen uchi',  'yokomen uchi'],
  },
  {
    title: 'Hamni handachi',
    attacks: ['Kaiten nage from katate dori', 'shiho nage from shomen uchi on the move',  'irime nage from shomen uchi on the move'],
  },
  {
    title: 'Ushiro ryote dori',
    variations: ['Three ways'],
  },
  {
    title: 'Tachi dori',
    variations: ['Kokyu ho', 'kokyu ho - blind turn', 'kokyu nage (chin)', 'kokyu nage (chin) - blind turn'],
  },
  {
    title: 'Jo dori',
    variations: ['Kokyu nage - ikkyo principle x 2', 'shiho nage omote & ura'],
  },
  {
    title: 'Tanken dori',
    variations: ['Gokyo 3 ways'],
  },
  {
    title: 'Tobi ukemi to each of',
    variations: ['Irimi', 'shiho nage', 'kote gaeshi', 'tenchi nage', 'juuji garami', 'tachi dori', 'jo dori'],
  },
  {
    title: 'Jiyu waza',
    variations: ['Two attackers'],
  },
  {
    title: 'Suwari waza',
    variations: ['Kihon'],
  },
];

export const SHODAN_CONTENT = `
<p>Minimum requirement:- 3 years Min. 300 days training.<br />
Sensei’s choice from technical categories & attacks. (Duration of test 30 minutes ).</p>
`;

export const KYU_RANKS = [
  {
    title: '1st kyu',
    requirements: SIXTY_CLASSES,
    colour: 'brown',
    pinning: `
      <p>To be performed from both standing and kneeling (tachi waza and suwari waza)</p>
      <h3>Ikkyo – Rokkyo</h3>
      <ul>
        <li>Shomen uchi</li>
        <li>Yokomen Uchi</li>
        <li>Tsuki</li>
        <li>Katate dori dori</li>
        <li>Sode guchi</li>
      </ul>
    `,
    throws: `
      <ul>
        <li>Kubi Nage from the following attacks:
          <ul>
            <li>Shomen uchi</li>
            <li>Yokomen uchi</li>
            <li>Tsuki</li>
          </ul>
        </li>
        <li>Shomen uchi irimi nage</li>
        <li>Mune dori kote gaeshi</li>
        <li>Tsuki kokyu nage</li>
        <li>Katate dori dori koshi nage</li>
        <li>Shomen uchi koshi nage</li>
        <li>Yokomen uchi koshi nage</li>
      </ul>
    `,
    suwariWaza: `Nage grabs uke - double yonkyo`,
    weapons: `
      <ul>
        <li>Ken suburi 1-7</li>
        <li>Jo suburi 1-20</li>
      </ul>
    `,
  },
  {
    title: '2nd kyu',
    colour: 'purple',
    requirements: SIXTY_CLASSES,
    pinning: `
      <p>To be performed from both standing and kneeling (tachi waza and suwari waza), omote and ura</p>
      <h3>Rokkyo</h3>
      <ul>
        <li>Tsuki</li>
        <li>Katate dori dori</li>
        <li>Sode guchi</li>
      </ul>
    `,
    throws: `
      <ul>
        <li>Ryote dori tenchi nage omote and ura</li>
        <li>Mune dori juji garami</li>
        <li>Ushiro ryote dori juji garami</li>
        <li>Ushiro kubi jime juji garami</li>
      </ul>
    `,
    suwariWaza: `Ryo Eri Jime (version2) - strike to neck`,
    weapons: `13 jo kata`,
  },
  {
    title: '3rd kyu',
    colour: 'red',
    requirements: SIXTY_CLASSES,
    pinning: `
      <p>To be performed from both standing and kneeling (tachi waza and suwari waza)</p>
      <h3>Gokyo  ura waza (pin #5) from the following attacks</h3>
      <ul>
        <li>Shomen uchi</li>
        <li>Tsuki</li>
        <li>Katate dori dori</li>
      </ul>
    `,
    throws: `
      <ul>
        <li>Katate dori dori kaiten nage:  uchi mowari &amp; soto mowari</li>
        <li>Tsuki kaiten nage</li>
        <li>Shomen uchi kaiten nage</li>
      </ul>
    `,
    suwariWaza: `Ryo Eri Jime (version1) - grab the kitten's scruff`,
    weapons: `Ken: 5th &amp; 7th ken suburi practices kihon &amp; awaze`,
  },
  {
    title: '4th kyu',
    colour: 'green',
    requirements: SIXTY_CLASSES,
    pinning: `
      <p>To be performed from both standing and kneeling (tachi waza and suwari waza), omote and ura</p>
      <h3>Yonkyo (pin #4) from the following attacks</h3>
      <ul>
        <li>Shomen uchi</li>
        <li>Yokomen uchi</li>
        <li>Tsuki</li>
      </ul>
    `,
    throws: `
      <p>Kokyu nage from</p>
      <ul>
        <li>Katate dori dori (shihonage style)</li>
        <li>Yokomen uchi</li>
        <li>Tsuki</li>
      </ul>
      <p>Tenchi nage ryoti dori, omote and ura</p>
    `,
    suwariWaza: `Elbows held`,
    weapons: `Ken migi and hidari awaze`,
  },
  {
    title: '5th kyu',
    colour: 'blue',
    requirements: THIRTY_CLASSES,
    pinning: `
      <p>To be performed from both standing and kneeling (tachi waza and suwari waza), omote and ura</p>
      <h3>Sankyo (pin #3) from the following attacks</h3>
      <ul>
        <li>Shomen uchi</li>
        <li>Katate dori dori</li>
        <li>Soda guchi</li>
      </ul>
    `,
    throws: `
      <ul>
        <li>Katate dori dori shihonage based katami waza, omote & ura waza</li>
        <li>Katate dori dori shihonage, omote & ura waza</li>
      </ul>
    `,
    suwariWaza: `Pushing down (version 2 - like kokyu ho)`,
    weapons: `jo suburi 11-20`,
  },
  {
    title: '6th kyu',
    colour: 'orange',
    requirements: THIRTY_CLASSES,
    pinning: `
      <p>To be performed from both standing and kneeling (tachi waza and suwari waza), omote and ura</p>
      <h3>Nikkyo (pin #2) from the following attacks</h3>
      <ul>
        <li>Shomen uchi</li>
        <li>Katate dori dori</li>
      </ul>
    `,
    throws: `
      <p>Kote gaeshi from</p>
      <ul>
        <li>Mune dori</li>
        <li>Katate dori dori</li>
      </ul>
    `,
    suwariWaza: `Pushing down (version 1 - dive into the pool)`,
    weapons: `jo suburi 1-10`,
  },
  {
    title: '7th kyu',
    colour: 'yellow',
    requirements: THIRTY_CLASSES,
    pinning: `
      <p>To be performed from both standing and kneeling (tachi waza and suwari waza), omote and ura</p>
      <h3>Ikkyo (pin #1) from the following attacks</h3>
      <ul>
        <li>Shomen uchi</li>
      </ul>
    `,
    throws: `
      <ul>
        <li>Shomen uchi irime nage</li>
        <li>Morote dori kokyu ho</li>
      </ul>
    `,
    suwariWaza: `Kihon`,
    weapons: `Ken suburi #1`,
  }

];

export const KIDS_RANKS = [
  {
    title: `Kid's 1st kyu`,
    colour: 'brown',
    content: `All techniques from all grades below brown`, 
  },
  {
    title: `Kid's 2nd kyu`,
    colour: 'purple',
    content: `Suwari waza kokyu ho (kneeling two handed throw)`, 
  },
  {
    title: `Kid's 3rd kyu`,
    colour: 'red',
    content: `Kote gaeshi (wrist twist throw)`, 
  },
  {
    title: `Kid's 4th kyu`,
    colour: 'green',
    content: `Ryote dori kokyu ho (spill the drink, wash the bench)`, 
  },
  {
    title: `Kid's 5th kyu`,
    colour: 'blue',
    content: `Irime nage (rainbows and unicorns)`, 
  },
  {
    title: `Kid's 6th kyu`,
    colour: 'orange',
    content: `Ikkyo omote & ura from standing (pin #1)`, 
  },
  {
    title: `Kid's 7th kyu`,
    colour: 'yellow',
    content: `Sit fall, shiko (knee walking), ta na henko & morote dori kokyu ho`, 
  },

]