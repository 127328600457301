import React from 'react';
import './Honeypot.css';

class Honeypot extends React.Component {
  constructor(props) {
    super(props);

    const alpha = '0123456789abcdef';
    let hash = '';
    for (let i=0; i<16; i++) {
      const pos = Math.floor(Math.random() * 16);
      hash += alpha.substr(pos,1);
    }

    this.state = {
      hash: hash,
      valid: null,
    }
  }

  onChange = (e) => {
    this.setState({
      value: e.target.value,
      valid: false,
    });
    
    const valid = e.target.value.length > 0 ? false : true;

    // Dispatch to parent
    this.props.canSubmit(valid);
  }

  onBlur =  (e) => {
    this.setState({
      value: e.target.value,
      valid: false,
    });

    // dispatch to parent

  }

  render() {
    const { hash, value } = this.state;
    return(
      <div className="hp">
        <label htmlFor={hash}>{hash}</label>
        <input 
          name={hash}
          onChange={this.onChange}
          onBlur={this.onBlur}
          value={value}
          tabIndex="-1"
          aria-hidden={true} />
      </div>
    );
  }
}

export default Honeypot;