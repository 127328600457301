import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import ImageHeading from '../../components/image/ImageHeading';
import { NAV } from '../../components/nav/Nav.data'
import './Students.css';

class Students extends React.Component {
  constructor(props) {
    super(props);

    let menu = null;

    for (let i=0; i<NAV.length; i++) {
      if ('Students' === NAV[i].title) {
        menu = i;
        break;
      }
    }

    this.state = {
      menu
    }
  }

  renderNav(menus) {
    if (!menus) return null;
    let links = [];

    for (let i=0; i<menus.length; i++) {
      const { path, title, subMenu } = menus[i];
         
      links.push(
        <li key={i}>
          <Link to={path}>{title}</Link>
          {this.renderNav(subMenu)}
        </li>
      );
    }

    return (
      <ul>
        {links}
      </ul>
    );
  }

  render() {
    const { menu } =  this.state;
    const submenu = NAV[menu].subMenu;
    const title = "Students";
    const description = "Resources for students of Aikido Maai";
    return (
      <div className="students">
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
        <ImageHeading {...this.props} title="Students" />
        <section>
          <h2>A collection of resources for prospective and current students</h2>
          {this.renderNav(submenu)}
        </section>
      </div>
    );
  }
}

export default Students;
