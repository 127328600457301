import React from 'react';
import './Label.css';

class Label extends React.Component {
  render() {

    const { label, name, required } = this.props;
    return (
      <label htmlFor={name}>
        {label}
        {required ? <span className="required">*</span> : null}
      </label>
    );
  }
}

export default Label;