export const LANDING_PAGES = [
    {
    title: 'Learn Aikido',
    path: '/learn-aikido',
    image: 'techniques-samurai-roots.jpg',
    content: [
      `<h2>Become your best self by training Aikido</h2>
      <ul>
        <li>Learn mindfulness</li>
        <li>Achieve your goals</li>
        <li>Improve your fitness</li>
        <li>Improve your situational awareness</li>
        <li>Learn to defend yourself effectively</li>
        <li>Learn leadership skills</li>
        <li>Become your best self</li>
      </ul>`,
      `<h2>Ancient roots, modern martial arts</h2>
      <p>Aikido teaches non-combative self defence, and always takes the approach that there are multiple attackers. The technical roots of Aikido lie in samurai weapons and the traditional Japanese art of ju jitsu. As a philosophy and a martial art, aikido teaches how to blend, and not to clash, with our training partners. This also applies to conflicts within our daily lives, whether physical or verbal.</p>
      <h2>You will be on the fast track to learn Aikido</h2>
      <p>When starting Aikido training at Aikido Maai, you will train with senior students - usually 1st dan black belt, or higher. This means that you get the expert attention you need to learn rapidly. Having senior students instructing you every class helps you learn the techniques faster.</p>`,
      `<p>Open handed classes at Aikido Maai follow this format:</p>
      <ul>
        <li>Ta na henko (a practice to learn basic hip movement)</li>
        <li>Morote Dori kokyu ho (a practice in hip moevement, non-competition and developing breath power – kokyu)</li>
        <li>A pinning technique</li>
        <li>A throwing technique</li>
        <li>Suwari waza kokyu ho (kneeling breath power practice)</li>
      </ul>
      <p>Weapons classes cycle through ken (wooden sword), jo (wooden staff) and ken tai jo (sword vs staff) throughout the year. Classes follow this format:</p>
      <ul>
        <li>Suburi (basic practices: 7 for ken, 20 for jo)</li>
        <li>Awase practices (blending practices)</li>
        <li>Partner practices</li>
        <li>31 &amp; 13 jo kata</li>
      </ul>`,
      `<h2>What will you learn from studying aikido weapons?</h2>
      <p>Aikido weapons teaches you mindfulness and awareness. We practice with ken (wooden sword) and jo (wooden staff). We do not wear padding, or armour like in kendo. This invites us to be fully aware of ourselves and our partners. We practice with a range of safety measures in place, such as:</p>
      <ul>
        <li>2 second break between each move when practicing partner practices</li>
        <li>The tips of the weapons are always placed 2.5cm from the target (in kihon - basic practices)</li>
      </ul>
      <p>We practice the basic moves until we are competant with them, then we move on to partner practices. The partner practices are done slowly, accurately and with the safety measures mentioned above. Once the kihon versions have been practiced, we move on to awase, or blending versions of the practices. We still maintain a 2 second gap between each step of the practice.</p>`,
    ]
  },
  {
    title: "Kid's Aikido",
    image: 'aikido-kids-classes.jpg',
    path: '/childrens-aikido-classes',
    content: [
      `<h2>Your child will gain valuable experiences, like:</h2>
      <ul>
        <li>Becoming their best selves</li>
        <li>Feeling good about themselves and experiencing positive self image</li>
        <li>Feeling self confident</li>
        <li>Respecting themselves and others</li>
        <li>Being collaborators and team players</li>
        <li>Resolving conflict without resorting to violence</li>
      </ul>`,
      `<p>The kids train in pairs under the guidance of Sensei Ajax. We have a great group of kids who all want to be here and train, and together we have fun and learn valuable life experiences.</p>
      <ul>
        <li>Children are encouraged to turn up 15 minutes before class to help put down the mats - this means that you need to bring your kids to the dojo by 4:45pm on Wednesdays</li>
        <li>Training starts at 5:00pm</li>
        <li>We train for 45 minutes - practicing basic techniques that teach children how to get out of the way of attacks.
        The same basic aikido techniques that are taught in the adult's basics class are practiced by the children: ta na henko, and morote dori kokyu ho, then we practice one of either a pin, or a throw. The pins and throws alternate each week and are chosen for their basic applications, or similarity to attacks that children might face in the playground.
        The focus is on the basics and the appropriate level of control for your child's age and level of ability.</li>
        <li>If everyone trains well there is a reward of 15 minutes of games (these games are techniques and basic martial arts concepts in disguise - but don't tell your kids this)</li>
      </ul>`,
      `<h2>Who runs the classes?</h2>
      <p>The class is run by Sensei Ajax (4th Dan) and senior students, including previous children's class participants.</p>
      <h2>Do instructors have working with children checks?</h2>
      <p>Absolutely. We take the children's safety very seriously and Aikido Maai complies with legislation for working with children. Please ask us for our working with children check cards when you come in to claim your two free classes.</p>
      <h2>How old does my child have to be?</h2>
      <p>We accept students who are at between 7 and 12 years of age. When your child turns 12, they are welcome to join the basics and weapons classes (Mondays and Wednesdays).</p>
      <h2>Do you grade the children?</h2>
      <p>We grade the children twice a year from white belt to a children's brown belt level. When they are 12 and join the basics class, the students are graded to 7th Kyu (yellow belt). Giving belt colours and grading the children helps reward them for their effort and show their progress throughout their training.</p>
      <h2>Children's Aikido Class Times</h2>
      Children's Aikido classes run every Wednesday  from 5:00–6pm during school terms.
      <h2>Training Fees</h2>
      <p>Children's classes cost $20 per class per child. Fees are due at the start of each term and can be paid online, or in cash.</p>
      `,
    ]
  },
];