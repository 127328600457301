import React from 'react';
import DanRankRow from './DanRankRow';
import './DanRankRow.css';

class DanRank extends React.Component {
  constructor(props) {
    super(props);

    let hash = (window.location.hash) ? window.location.hash.substring(1) : null;

    this.state = {
      grade: ('grade' === hash) ? true : false,
      source: [...props.source],
      total: 0,
      unscored: props.source.length
    };
  }

  onChange = (value, pos) => {
    const source = [...this.state.source];
    source[pos] = {
      ...source[pos],
      score: parseInt(value)
    }

    this.setState({
      source: source
    });

    this.calcTotal(source);
  }

  /**
   * Calculate the test total
   * 
   * @param {object} source: the array of techniques
   */
  calcTotal = (source) => {
    const count = source.length;
    let unscored = source.length;
    let total = 0;

    for (let i=0; i<count; i++) {
      if (source[i].score) {
        total += source[i].score;
        --unscored;
      }
    }

    this.setState({
      total,
      pass: total >= ((count - unscored) * 3) ? true : false,
      unscored,
    });
  }

  renderTechniques = () => {
    const { source } = this.state;
    let techniques = [];
    for (let i=0; i<source.length; i++) {
      techniques.push(
        <DanRankRow
          key={i}
          pos={i}
          {...source[i]}
          {...this.state}
          onChange={this.onChange}
        />
      );
        
    }
    return techniques;
  }

  render() {
    const { content } = this.props;
    const { grade, pass, source, total, unscored } = this.state;

    const scored = source.length - unscored;
    const max = source.length * 5;
    const current = scored * 5;
    const percent = current > 0 ? (100 * total/current).toFixed(2) : 0;

    return (
      <div className="rank">
        {
          grade
            ? (
                <div>
                  <p>For each technique select a number from 1 – 5; where 1 is poor, 3 is at level, and 5 is excellent</p>
                </div>
              )
            : <div dangerouslySetInnerHTML={{__html: content}}/>
        }
          
        <div className="techniques">
          {this.renderTechniques()}
        </div>
        {
        grade
          ? (
              <div className="summary">
                <div className={`total ${ pass ? 'pass' : 'fail'}`}>
                  Total: {total} [{ pass ? 'PASS' : 'FAIL'}] 
                </div>
                <div className="max">
                  Out of {current} current = {percent}%
                  <div>{max} total possible points in this test</div>
                </div>
                <div className="unscored">
                  {
                    unscored === 0
                      ? null
                      : `(You have not scored  ${unscored} techniques)`
                  }
                  
                </div>
              </div>
            )
          : <div dangerouslySetInnerHTML={{__html: content}}/>
      }
      </div>
    );
  }
}

export default DanRank;
