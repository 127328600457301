import React from 'react';
import {Helmet} from 'react-helmet';
import ImageHeading from '../image/ImageHeading';
import SearchForm from './SearchForm';
import Pagination from '../pagination/Pagination';
import './SearchResults.css';

// Data sources
import { FAQS, FAQ_IMAGE } from '../../pages/faq/Faq.data';
import { PAGES } from '../page/Pages.data';
import { NAV } from '../nav/Nav.data';
import { CLASSES } from '../../pages/classes/Classes.data';
import { IMAGE_ROOT } from '../../data/Data';
import { PRODUCTS } from '../../pages/shop/Products.data';
import { LANDING_PAGES } from '../landing-pages/LandingPages.data';

const LIMIT = 10;
const NO_RESULTS_IMAGE = `${IMAGE_ROOT}aikido-maai-no-search-results.jpg`;

const IMAGE = 'aikido-search.jpg';

class SearchResults extends React.Component {
  constructor(props) {
    super(props);

    // Get search term from URL
    const urlParams = new URLSearchParams(window.location.search);
    const query = urlParams.get('search');

    let nav = [];
    // Flatten the navigation array
    for (let i=0; i< NAV.length; i++) {
      const { path, title, content, image, subMenu } = NAV[i];
      
      nav.push({
       image,
       content,
       path,
       title,
      });

      if (subMenu) {
        for (let x=0; x<subMenu.length; x++) {
          const { path, title, content, image } = subMenu[x];
          nav.push({
            image,
            content,
            path,
            title,
           });
        }
      }
    }

    this.state = {
      page: 0,
      results: [],
      query: query ? query : '',
      data: [
        {type: 'nav', data: nav},
        {type: 'product', data: PRODUCTS},
        {type: 'class', data: CLASSES},
        {type: 'page', data: PAGES},
        {type: 'faq', data: FAQS},
        {type: 'landing-page', data: LANDING_PAGES},
      ],
    };
  }

  componentDidMount() {
    this.search(this.props.query, this.state.data);
  }

  componentWillReceiveProps(props) {
    this.search(props.query, this.state.data);
  }

  /**
   * Show a single post
   */
  showSingle = (result) => {
    const { history } = this.props;
    if (result.data.hasOwnProperty('path')) {
      history.push(result.data.path);
      window.scrollTo(0, 0);
      return 'path';
    }

    let { title, id } = result.data;

    switch(result.type) {
      case('faq'):
        id = title.replace(/[^a-z0-9]/gmi, '').replace(/\s+/g, '');
        history.push(`/about/faq#${id}`);
        return 'faq';

      case('class'):{
        history.push(`/classes#${id}`);
        return 'class';
      }
      default:
        return true;
    }
  }

  /**
   * Choose page of results
   */
  page = (page) => {
    this.setState({
      page: page
    });
  }

  /**
   *  Do the search
   */
  search = (query, data) => {
    if (undefined === query || query === '') {
      this.setState({
        results: []
      });
      return false;
    }

    let results = [];
    // TODO:
    // trim whitepaces
    // add multiple word handling
    let re = new RegExp(query,'ig');

    for (let i=0; i<data.length; i++) {
      let sources = data[i].data;
      let type = data[i].type;

      for (let x=0; x<sources.length; x++) {
        const source = sources[x];
        let { content, title }  = source;

        if (type === 'landing-page') {
          content = content.join();
        }

        const isInResults = this.isInResults(title, results);

        if ((re.test(title) || re.test(content)) && !isInResults) {
          results.push({
            type: type,
            data: {
              ...source,
              content
            }
          });
        }
      }
    }

    this.setState({
      results: results
    });
  }

  /**
   * isInResults
   */
  isInResults = (title, results) => {
    for (let i=0; i<results.length; i++) {
      if (results[i].data.title === title) {
        return true;
      }
    }
    return false;
  }
  /**
   * Results limit
   */
  resultsLimit = () => {
    const { results, page } = this.state;
    const nextPage = page + 1;
    switch(true) {
      case (results.length < 10):
        return results.length;
      
      case(page === 0 && results.length > 10):
        return LIMIT;
      
      case(page > 0 && results.length > 10 && results.length < (nextPage * LIMIT)):
        return results.length;

      case(page > 0 && results.length > 10 && results.length >= (nextPage * LIMIT)):
        return nextPage * LIMIT;

      default: 
        return LIMIT;
    }
  }

  renderImage = (type, data) => {
    const image = (data.image) ? data.image : 'hokusai_waves_narrow.jpg' ;
    
    switch(type) {
      case('nav'):
        return (
          <div className="image" style={{backgroundImage: `url(${IMAGE_ROOT}${image})`}} />
        );
      
      case('class'):
        return (
          <div className="image" style={{backgroundImage: `url(${IMAGE_ROOT}${image.src})`}} />
        );
      
      case('faq'):
        return (
          <div className="image" style={{backgroundImage: `url(${IMAGE_ROOT}${FAQ_IMAGE})`}}>
            <div className="text-container">
              <div className="text">FAQ</div>
            </div>
          </div>
        );

      default:
        return <div className="image" style={{backgroundImage: `url(${IMAGE_ROOT}${image})`}} />;
    }
  }

  renderResults() {
    const { results, page } = this.state;
    let contents = [];
    const start = (page * LIMIT);
    const limit = this.resultsLimit();

    for (let i=start; i<limit; i++) {
      const type = results[i].type;
      const data = results[i].data;
      const title = data.title;
      const regex = /(<([^>]+)>)/ig;
      const content = (results[i].data.content) ? results[i].data.content : '';      
      const extract = (content.length > 150) ? `${content.replace(regex, '').substr(0, 150)}...` : content ;

      contents.push(
        <div className={`result`} key={i} onClick={() => this.showSingle(results[i])}>
          {this.renderImage(type, data)}
          <h2>{title}</h2>
          <p>{extract}</p>
        </div>
      );
    }
    return (
      <div className="listing">
        {contents}
      </div>
    );
  }

  renderNoResults() {
    const { results }  = this.state;
    const { query }  = this.props;

    if (results.length === 0 && query.length !== 0) {
      return(
        <div className="no-results" style={{backgroundImage: `url(${NO_RESULTS_IMAGE})`}}>
          <div className="no-results-text">
            <div className="text">
               No results...
            </div>
          </div>
        </div>
      );
    }

    if (results.length === 0 && query.length === 0) {
      return (<p>Seek and you shall find</p>);
    }

    return null;
  }

  render() {
    const { results } = this.state;
    const {query} = this.props;
    const title = query.length > 0 ? `Search results for ${this.props.query}` : 'Search';
    const description = results.length > 0 ? `${results.length} search results for "${this.props.query}"` : 'Search results page';

    return (
      <div className="search-results">
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
        <ImageHeading title="Search" image={IMAGE} />
        <section>
          <SearchForm {...this.props} />
          {this.renderNoResults()}
          {this.renderResults()}
          <Pagination items={results} current={this.state.page} page={this.page}/>
        </section>
      </div>
    );
  }
}

export default SearchResults;
