import fn from '../../functions/Functions';

const classes = fn.nextClassesString(4);
const options = [{
  name: 'Select class',
  value: 'Select class',
  selected: false,
}];

for (let i=0; i<classes.length; i++) {
  options.push({
    name: classes[i],
    value: classes[i],
    selected: false,
  });
}

export const CHECKIN = {
  name: 'Check in form',
  title: 'Check in',
  id: 'check-in',
  className: 'check-in-form',
  inputs: [
    {
      className: 'name',    
      type: 'text',
      label: 'Your Name',
      name: 'name',
      placeholder: 'Your name',
      required: true,
    },
    {
      className: 'child-name',    
      type: 'text',
      label: 'Child\'s Name(s)',
      name: 'childName',
      placeholder: 'Child\'s name(s)',
      required: false,
    },
    {
      className: 'mobile',    
      type: 'phone',
      label: 'Mobile',
      name: 'mobile',
      placeholder: '0404 123 456',
      required: true,
    },
    {
      className: 'check-in-class',    
      type: 'select',
      label: 'Check-in for class',
      name: 'checkInClass',
      required: true,
      options: options,
      value: '',
    },
    {
      className: 'pay',    
      type: 'radio',
      label: 'Pay for class',
      name: 'pay',
      options: [
        {
          label: "I'll pay online now",
          value: 'Pay online now',
          selected: true
        },
        {
          label: "I'll pay by bank transfer",
          value: 'Bank Transfer'
        },
        {
          label: "I'll pay cash",
          value: 'Cash'
        },
        {
          label: "I've already paid",
          value: 'Paid',
        },
        {
          label: "I'm doing a free trial class",
          value: 'Trial',
        },
      ],
      required: true,
      valid: true,
      value: 'Online',
    },
  ],
  success: {
    message: "Thanks for checking in."
  }
}