import React from 'react';
import {Helmet} from 'react-helmet';
import ImageHeading from '../../components/image/ImageHeading';
import { FAQS, FAQ_IMAGE } from './Faq.data';
import SingleFaq from './SingleFaq';
import Sidebar from '../../components/sidebar/Sidebar';
import './Faq.css';

class Faq extends React.Component {
  renderFaqs = () => {
    let faqs = [];
    for (let i=0; i<FAQS.length; i++) {
      const title = FAQS[i].title;
      const content = FAQS[i].content;
      faqs.push(<SingleFaq key={i} title={title} content={content} />);
    }
    return faqs;
  }

  render() {
    const title = "FAQS";
    const description = "Aikdo Maai: FAQs about Aikido";
    return (
      <div className="faqs">
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
        <ImageHeading title="FAQ" image={FAQ_IMAGE} />
        <section>
          <div className="content">
            {this.renderFaqs()}
          </div>
          <Sidebar {...this.props} />
        </section>
      </div>
    );
  }
}

export default Faq;
