import React from 'react';
import {Helmet} from 'react-helmet';
import ImageHeading from '../image/ImageHeading';
import FourOhFour from '../fourohfour/FourOhFour';
import Sidebar from '../sidebar/Sidebar';
import ClassDetails from '../class-details/ClassDetails';
import { PAGES } from './Pages.data';
import './Page.css';

class Page extends React.Component {

  render() {
    const location = this.props.location;

    // If we are at a single page render it, of if unfound render 404
    for (const [index,value] of PAGES.entries()) {
      const {content, description, image, path, title } = value;

      if (path === location.pathname) {

        return (
          <article className="page single" key={index}>
            <Helmet>
              <title>{title}</title>
              <meta name="description" content={description} />
            </Helmet>
            <ImageHeading image={image} title={title}/>
            <section>
              <div className="content" dangerouslySetInnerHTML={{__html: content}} />
              {
                path === '/covid-19'
                  ? <ClassDetails />
                  : null
              }
              <Sidebar />
            </section>
          </article>
        )
      }
    }

    return <FourOhFour />;
  }
}

export default Page;