/**
 * Text input
 * @flow
 */
import React from 'react';
import Label from '../label/Label';
import Error from '../error/Error';
import fn from '../../functions/Functions';
import {  EMAIL_REGEX, MOBILE_REGEX } from '../../data/Data';
import type { localforage } from 'localforage';
import type { BugSnag } from '@bugsnag/js';
import type { FormType } from '../../types/Types';
import './TextInput.css';

type Props = {
  bugsnagClient: BugSnag,
  conditional: boolean,
  conditions: {
    field: string,
    name: string,
    value: boolean
  },
  className: string,
  form: FormType,
  label: string,
  localforage: localforage,
  name: string,
  setValues: (name: string, value: string, valid: boolean | null) => *,
  type: string,
  placeholder: string,
  required: boolean,
  valid: boolean | null,
};

type State = {
  error: boolean,
  errorMessage: string,
  type: string,
  valid: boolean | null,
  value: string,
}

const TYPES = ['text', 'email', 'password', 'phone'];

class TextInput extends React.Component<Props, State> {
  static defaultProps = {
    conditional: false,
    className: 'text-input',
  };

  constructor(props: Props) {
    super(props);

    const type = (props.type && TYPES.indexOf(props.type) !== -1) ? props.type : 'text';

    this.state = {
      error: false,
      errorMessage: '',
      type: type,
      valid: null,
      value: '',
    }
  }

  componentDidMount() {
    this.reloadFields();
  }

  /**
   * load from localforage
   */
  reloadFields = () => {
    const { localforage, name } = this.props;

    if (!localforage) return null;
    
    localforage.getItem(name)
      .then(
        (value) => {
          if (!value) return; 
          this.validate(value);
        }
      )
  }

  setLocalForage = (name: string, value: string) => {

    const { localforage, bugsnagClient } = this.props;

    if (!localforage ) return null;

    localforage.setItem(name, value)
      .catch(
        (error) => {
          bugsnagClient.notify(error)
        }
      );
  }

  /**
   * onChange
   */
  onChange = (value: string) => {
    this.validate(value);
  }

  /**
   * onBlur
   */
  onBlur = (value: string) => {
    this.validate(value);
  }

  /**
   * Validate
   */
  validate = (value: string ) => {
    const { name, required, label, setValues} = this.props;
    const { type } = this.state;
    let valid = null;
    let errorMessage = '';

    this.setLocalForage(name, value);

    switch(true) {
      case (required && (value === undefined || value.length === 0)): 
        valid = false;
        errorMessage = !valid ? `${label} is required` : '';
        break;
      
      case(type === 'email'):
        valid = EMAIL_REGEX.test(value) ? true : false
        errorMessage = !valid ? `${label} not valid` : '';
        break;

      case(type === 'phone'):
        valid = MOBILE_REGEX.test(value) ? true : false
        errorMessage = !valid ? `${label} not valid` : '';
        break;

      default: 
        valid = value.length > 0 ? true : false
        errorMessage = '';
    }

    this.setState({
      error: !valid,
      errorMessage,
      valid,
      value,
    }, () => setValues(name, value, valid));
  }

  showField = () => {
    const { conditional, conditions, form } = this.props;
    const inputs = form.inputs;

    let show = false;

    if (conditional && conditions) {
      const field = conditions.field;
      const value = conditions.value;
      for (var i=0; i<inputs.length; i++) {
        
        if (inputs[i].name === field && inputs[i].value === value) {
          show = true;
        }
      }
    }
    return show;
  }

  render() {
    const {
      conditional,
      conditions,
      className,
      form,
      name,
      placeholder,
      required,
      valid,
    } = this.props;

    const {
      error,
      errorMessage,
      type,
      value,
    } = this.state;

    let show = '';

    if (conditional) {
        show = fn.showField(conditional, conditions, form) ? '' : ' hidden';
    }

    return (
      <div className={`field ${className}${show}`}>
        <Label {...this.props} />
        <input
          className={`${(valid === false) ? 'invalid' : ''}`}
          type={type}
          name={name}
          placeholder={placeholder}
          required={required}
          onChange={(e) => this.onChange(e.target.value)}
          onBlur={(e) => this.onBlur(e.target.value)}
          value={value}
        />
        <Error error={error} errorMessage={errorMessage} />
      </div>
    )
  }
}

export default TextInput;
