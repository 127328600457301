import React from 'react';
import ImageHeading from '../../components/image/ImageHeading';
import Form from '../../components/forms/Form';
import { CONTACT_FORM } from './ContactForm.data';
import Address from '../../components/address/Address';
import './Contact.css';

const IMAGE = 'aikido-maai-contact.jpg';

class Contact extends React.Component {
  render() {
    return (
      <div>
        <ImageHeading {...this.props} title="Contact" image={IMAGE} />
        <section className="contact">
          <Form {...this.props} form={CONTACT_FORM} />
          <Address {...this.props} />
        </section>
      </div>
    );
  }
}

export default Contact;