/**
 * Learn Aikido Dot Points
 * 
 * @flow
 */
import React from 'react';
import InterestForm from '../forms/InterestForm';
import {IMAGE_ROOT} from '../../data/Data';
import type BugsnagClient from '@bugsnag/js';
import './LearnAikidoDotPoints.css';

type Props = {
  kids: boolean,
  jest: boolean,
  bugsnagClient: BugsnagClient
};

const LEARN = [
  {
    image: 'mindlfulness.jpg',
    text: 'Learn mindfulness',
  },
  {
    image: 'goals.jpg',
    text: 'Achieve your goals',
  },
  {
    image: 'aikido-samurai-1.jpg',
    text: 'Improve your fitness',
  },
  {
    image: 'Kuniyoshi_100_Brave_Generals_Takeda_Samanosuke.jpg',
    text: 'Improve your situational awareness',
  },
  {
    image: 'techniques-samurai-roots.jpg',
    text: 'Learn to defend yourself effectively',
  },
  {
    image: 'aikido-faqs.jpg',
    text: 'Become your best self',
  },
];

const KIDS_LEARN = [];

class LearnAikidoDoPoints extends React.Component<Props> {
  static defaultProps = {
    jest: false,
    kids: false
  };

  render() {
    const {kids} = this.props;
    const learn = kids ? KIDS_LEARN : LEARN;
    const title = kids ? '' : 'Become your best self by training Aikido';
    let points = [];

    for (let i=0; i<learn.length; i++) {
      const point = learn[i];
      const style = {backgroundImage: `url('${IMAGE_ROOT}${point.image}')`};

      points.push(
        <li key={i} className={`point-${i}`}>
          <div className="img" style={style}/>
          <span className="text">{point.text}</span>
        </li>
      );
    }

    return(
      <div className={`LearnAikidoDotPoints ${kids ? 'kids' : 'adults'}`}>
        <h2>{title}</h2>
        <ul>
          {points}
        </ul>
        <InterestForm {...this.props} />
      </div>
    );
  }
}

export default LearnAikidoDoPoints;
