import { COVID_SAFE_LIST } from '../../data/Data';

export const CLASSES = [
  
  // {
  //   title: 'Udansha (advanced)',
  //   content: 'The advanced class is for senior students to enjoy some vigorous training and practice advanced techniques',
  //   shortName: 'Advanced',
  //   id: 'udansha',
  //   day: 'Monday',
  //   start: '5:45pm',
  //   startHour: '17',
  //   startMinute: '45',
  //   end: '6:45pm',
  //   location: 'Syndal South Primary School',
  //   link: '/classes/udansha',
  //   students: 'Black belts, 1st kyu and 2nd kyus welcome',
  //   image: {
  //     alt: 'Aikido Udnasha - advanced class',
  //     src: 'aikido-udansha-advanced-class.jpg'
  //   },
  // },
  {
    title: 'Aikido open handed',
    content: `<p>Contact, open handed training on the mats.</p>
    <ul>
    ${COVID_SAFE_LIST}
    <li>Please wear your gi.</li>
    <li>Check the Upcoming Classes above for dates that training is on.</li>
    <li>Cost: Included in <a href="/shop#adult-monthly-subscription">monthly subscription</a>, or $20 per class. Payable through the <a href="/shop">shop</a>, bank transfer or cash.</li>
    </ul>`,
    id: 'aikido-open-handed',
    days: [{
      day: 'Monday',
      start: '6:00pm',
      startHour: '18',
      startMinute: '00',
      end: '7:30pm',
    },
    {
      day: 'Wednesday',
      start: '6:00pm',
      startHour: '18',
      startMinute: '00',
      end: '7:30pm',
    }],
    location: {
      title: 'Syndal South Primary School',
      link: 'https://www.google.com.au/maps/place/Aikido+Maai/@-37.8793188,145.1427096,17z/data=!4m12!1m6!3m5!1s0x6ad63ff133eaa88f:0xa6365b96a58c7844!2sAikido+Maai!8m2!3d-37.8797263!4d145.1434247!3m4!1s0x6ad63ff133eaa88f:0xa6365b96a58c7844!8m2!3d-37.8797263!4d145.1434247'
    },
    link: '/classes#aikido-open-handed',
    shortName: 'Open handed',
    students: 'Everyone, from beginner to advanced',
    image: {
      alt: 'Aikido basic open handed class',
      src: 'learn-aikido-throws.jpg'
    },
  },
  {
    title: 'Children\'s Aikido',
    content: `<p>A fun and stimulating class for kids 7 - 12 years old. 45 minutes of training and games.</p>
    <ul>
      ${COVID_SAFE_LIST}
      <li>Cost $20 per class, <a href="/shop">paid per term</a></li>
    </ul>
    `,
    id: 'childrens-aikido',
    shortName: 'Kids',
    days: [{
      day: 'Wednesday',
      start: '5:00pm',
      startHour: '17',
      startMinute: '00',
      end: '5:45pm',
    }],
    location: {
      title: 'Syndal South Primary School',
      link: 'https://www.google.com.au/maps/place/Aikido+Maai/@-37.8793188,145.1427096,17z/data=!4m12!1m6!3m5!1s0x6ad63ff133eaa88f:0xa6365b96a58c7844!2sAikido+Maai!8m2!3d-37.8797263!4d145.1434247!3m4!1s0x6ad63ff133eaa88f:0xa6365b96a58c7844!8m2!3d-37.8797263!4d145.1434247'
    },
    link: '/classes#childrens-aikido',
    students: '7 – 12 year olds',
    comments: 'Training only during school terms. Payment due at the start of term',
    image: {
      alt: 'Kids class',
      src: 'aikido-kids-classes-wide.jpg'
    },
  },
  {
    title: 'Aikido weapons (Bukiwaza)',
    content: `<p>Practice aiki ken and jo</p>
    <ul>
    ${COVID_SAFE_LIST}
    <li>Bring a water bottle</li>
    <li>Bring your weapons</li>
    <li>You do not need to wear full gi, or even a belt. Please wear comfy clothes, such as shorts, trackies or gi pants & t-shirt. </li>
    <li>Check the Upcoming Classes above for dates that training is on.</li>
    <li>Cost: Included in <a href="/shop#adult-monthly-subscription">monthly subscription</a>, or $20 per class. Payable through the <a href="/shop">shop</a>, bank transfer or cash.</li>
    </ul>`,
    id: 'covid-safe-weapons',
    days: [
      {
        day: 'Saturday',
        start: '10:00pm',
        startHour: '12',
        startMinute: '00',
        end: '11:30pm',
      }
    ],
    location: {
      title: 'Ringwood',
      link: null
    },
    link: '/classes#weapons',
    shortName: 'Weapons',
    students: 'Beginners to advanced welcome',
    image: {
      alt: 'Aikido ken and jo training',
      src: 'aikido-weapons-outdoors-mitcham.jpg'
    },
  },
  // {
  //   title: 'Weapons',
  //   content: 'Open handed and weapons are interlinked in Aikido. We learn from each stream to improve the other.',
  //   shortName: 'Weapons',
  //   day: 'Wednesday',
  //   start: '6:10pm',
  //   startHour: '18',
  //   startMinute: '10',
  //   end: '7:10pm',
  //   location: 'Syndal South Primary School',
  //   link: '/classes/weapons',
  //   students: 'Everyone, from beginner to advanced',
  //   image: {
  //     alt: 'Aikido weapons class',
  //     src: 'aikido-weapons-class-400x400.jpg'
  //   },
  // },
  // {
  //   title: 'Basics (open handed)',
  //   content: 'Everything is "basics". It\'s like practicing your scales on the piano. But we also let rip with some fun and invigorating techniques or fun.',
  //   shortName: 'Basics',
  //   day: 'Wednesday',
  //   start: '7:00pm',
  //   startHour: '19',
  //   startMinute: '00',
  //   end: '8:30pm',
  //   location: 'Syndal South Primary School',
  //   link: '/classes/basics',
  //   students: 'Everyone, from beginner to advanced',
  //   image: {
  //     alt: 'Aikido basic open handed class',
  //     src: 'aikido-basics-open-handed.jpg'
  //   },
  // },
];

// School term dates - Victoria
export const SCHOOL_TERMS = [
  { 
    title: 'Term 1 2021',
    start: '28 January 2021',
    end: '1 April 2021',
  },
  { 
    title: 'Term 2 2021',
    start: '19 April 2021',
    end: '25 June 2021',
  },
  { 
    title: 'Term 3 2021',
    start: '12 July 2021',
    end: '17 September 2021',
  },
  { 
    title: 'Term 4 2021',
    start: '4 October 2021',
    end: '17 December 2021',
  },
  { 
    title: 'Term 1 2022',
    start: '31 January 2022',
    end: '8 April 2022',
  },
  { 
    title: 'Term 2 2022',
    start: '26 April 2022',
    end: '24 June 2022',
  },
  { 
    title: 'Term 3 2022',
    start: '11 July 2022',
    end: '16 September 2022',
  },
  { 
    title: 'Term 4 2022',
    start: '3 October 2022',
    end: '20 December 2022',
  },
  { 
    title: 'Term 1 2023',
    start: '30 January 2023',
    end: '6 April 2023',
  },
  { 
    title: 'Term 2 2023',
    start: '24 April 2023',
    end: '23 June 2023',
  },
  { 
    title: 'Term 3 2023',
    start: '10 July 2023',
    end: '15 September 2023',
  },
  { 
    title: 'Term 4 2023',
    start: '2 October 2023',
    end: '20 December 2023',
  },

  { 
    title: 'Term 1 2024',
    start: '30 January 2024',
    end: '28 March 2024',
  },
  { 
    title: 'Term 2 2024',
    start: '15 April 2024',
    end: '28 June 2024',
  },
  { 
    title: 'Term 3 2024',
    start: '15 July 2024',
    end: '20 September 2024',
  },
  { 
    title: 'Term 4 2024',
    start: '7 October 2024',
    end: '20 December 2024',
  },
];

export const CLASSES_CONTENT = "<p>Anyone can train Aikido. Whether you are 7 or 99 years old.</p>";
export const CLASSES_IMAGE = 'vintage-japanese-woodblock-print-c-1950-5493.jpg';