/**
 * CheckIn
 * @flow
 */
import React from 'react';
import { Helmet } from 'react-helmet';
import ImageHeading from '../../components/image/ImageHeading';
import CheckinForm from './CheckinForm';
import type BugsnagClient from '@bugsnag/js';
import type { Mutate } from '../../types/Types';
import './Checkin.css';

type Props = {
  bugsnagClient: BugsnagClient,
  mutateBasket: (mutate: Mutate) => *,
}

const CheckIn = (props: Props) => {
  return (
    <article className="CheckIn">
      <Helmet>
        <title>Check in to class | Aikido Maai</title>
        <meta name="description" content="Check in to class" />
      </Helmet>
      <ImageHeading embed={false} title="Check in" image="aikido-maai-learn-aikido.jpg" />
      <section>
        <CheckinForm {...props} />
      </section>
    </article>
  );
}

export default CheckIn;
