/**
 * Product
 * @flow
 */
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IMAGE_ROOT } from '../../../data/Data';
import type { Mutate, Product as ProductType } from '../../../types/Types';

import './Product.css';

const DEFAULT_IMAGE = 'gi.jpg';

type Props = {
  mutateBasket: (action: Mutate) => *,
  product: ProductType
}

type State = {
  added: boolean,
  another: boolean,
  product: ProductType,
  weeks: number | null,
}

class Product extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const product = {...this.props.product};
    const { weeks } = product ? product : { weeks: null };

    this.state = {
      added: false,
      another: false,
      product,
      weeks
    }
  }

  addToCart = () => {
    const { mutateBasket } = this.props;
    const { product } = this.state;

    const mutate = {
      action: 'add',
      product
    }

    mutateBasket(mutate);

    this.setState({
      added: true,
      another: false
    });

    setTimeout(() => this.setState({another: true}), 2000);
  }

  changeWeeks = (e: SyntheticEvent<>) => {
    let { product } = this.state;
    e.preventDefault();
    // $FlowFixMe
    const { value } = e.target;

    const weeks = value;
    product.price = value * 20;
    product.weeks = weeks;

    this.setState({
      weeks,
      product,
    });

  }

  render() {
    const { added, another , product, weeks } = this.state;

    if (!product) return null;

    let { 
      image,
      name,
      id,
      price,
      description,
      subscription,
    } = product;

    image = image ? image : DEFAULT_IMAGE;

    let isKids = name.match(/kid/);

    return (
      <div id={id} className={`product${isKids ? ' kids' : ''}`}>
        <img src={`${IMAGE_ROOT}${image}`} alt={name} />
        <h2>{name}</h2>
        <div className="price">
          <strong>Price:</strong> ${price}
          {
            subscription
              ? <span className="subscription">(monthly recurring subscription)</span>
              : null 
          }
          
        </div>
        <div className="description">
          {description}
        </div>
        {
          weeks && isKids
            ? (
                <div className="adjust-weeks">
                  <p><FontAwesomeIcon icon="exclamation-triangle" /> If your child has had a free trial class, or you are joining in the middle of the term,
                  please adjust the number of weeks that you are paying for.</p>
                  <p>
                    Pay for
                    <input
                      type="number"
                      min="1"
                      max={this.props.product.weeks}
                      value={weeks}
                      onChange={this.changeWeeks}
                    />
                    weeks training
                  </p>
                </div>)
            : null
        }
        
        <button
          className={`button${another ? '  ' : added ? ' added' : ''}`}
          onClick={this.addToCart}
          tabIndex="0"
        >
          {
            another
            ? (
                <span className="add-another">
                  <FontAwesomeIcon icon="plus" />
                  Add Another
                </span>
              )
              : added 
                ? (
                    <span className="added-text">
                      <FontAwesomeIcon icon="check" />
                      Added!
                    </span>
                )
                : (
                    <span className="add">
                      <FontAwesomeIcon icon="plus" />
                      Add to cart
                    </span>
                  )
                
          }
        </button>
        {
          added
            ? <Link className="go" to="/cart">
                <FontAwesomeIcon icon="shopping-cart" />
                  Go to cart
                  <FontAwesomeIcon icon="chevron-right" />
              </Link>
            : null
        }
      </div>
    );
  }
}

export default Product;
