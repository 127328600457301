import React from 'react';
import InterestForm from '../forms/InterestForm';
import './Sidebar.css';

class Sidebar extends React.Component {
  render() {
    return (
      <div className="sidebar">
        <InterestForm />
      </div>
    );
  }
}

export default Sidebar;