export const PAGES = [
  {
    title: 'Test content',
    path: '/test',
    content: `<p>Test.</p>`,
    description: 'A test page',
    image: 'aikido-maaai-about.jpg',
  },
  {
    title: 'About',
    path: '/about',
    image: 'aikido-maaai-about.jpg',
    description: 'At Aikido Maai we pride ourselves in providing a safe, fun and inclusive space to learn to be our best selves.',
    content: `<p>
      At Aikido Maai we pride ourselves in providing a safe, fun and inclusive space to learn to be our best selves. Aikido provides a physical and philosophical framework to better manage conflict within ourselves, our family, work and social settings.  
    </p>
    <p>
      Learn more about Aikido:
      <ul>
        <li><a href="/what-is-aikido">What is Aikido?</a></li>
        <li><a href="/about/faq">Aikido FAQ</a></li>
        <li><a href="/aikido-maai-training-manual/dojo-etiquette">Dojo etiquette</a></li>
        <li><a href="aikido-maai-training-manual/terms-conditions-training">Terms and conditions of training</a></li>
        
      </ul>
    </p>
    `,
  },
  {
    title: 'Lineage',
    path: '/about/lineage',
    image: 'aikido-maai-ajax-receives-4th-dan.jpg',
    description: 'The lineage of the Instructors at Aikido Maai.',
    content: `<ol>
      <li>Ajax McKerral Sensei holds a 4th Dan Iwama Ryu &amp; 4th Dan Iwama Ryu buki waza and was taught by Michael Field Sensei.</li>
      <li>Michael Field Sensei (5th Dan Iwama Ryu and 5th Dan Aikikai) was Saito Sensei’s student between 1979 to 2000, and was appointed head of Iwama Ryu Aikido in Australia by Saito Sensei in 2000</li>
      <li>Morihiro Saito Sensei spent 23 years living and training with the founder of Aikido in Iwama, Japan.<br>Saito Sensei is the only person to have spent this length of time with the founder of Aikido, Morihei Ueshiba</li>
      <li>Morihei Ueshiba, founder of Aikido, perfected the art between 1945 and 1960 on his property in Iwama. Only Saito Sensei was present during the whole of this period. It was only during this time that the weapons syllabus was incorporated into Aikido.</li>
    </ol>
    `,
  },
  {
    title: 'What is Aikido?',
    path: '/what-is-aikido',
    image: 'japanese-woodblock-print.jpg',
    description: 'An interesting read about what Aikido is. Presented in a question and answer format.',
    content: `<p>That’s a very good question. And the short answer is that Aikido is different for everybody, and that everyone must discover for themselves what Aikido is for them.</p>
    <p>The longer answer is the subject of this article.</p>
    <h2>Some commonly held views of Aikido</h2>
    <p>When I tell people that I teach Aikido, many people say</p>
    <blockquote>
      <p>Oh, that’s where you use the energy of the attacker against them, isn’t it?</p>
    </blockquote>
    <p>Well, it is… and it isn’t.</p>
    <p>Aikido is more about a strategy like:</p>
    <ul>
      <li>Don’t be in a place where you can be hit</li>
      <li>Always resolve the situation to your advantage in the first move</li>
      <li>Take the path of least resistance</li>
      <li>Always aim to injury your attacker the least amount necessary to ensure everyone’s safety</li>
    </ul>
    <p>I prefer to think of Aikido as the <strong>art of non-resistance</strong>.</p>
    <h2>What does non-resistance mean?</h2>
    <p>In all of our daily lives we experience conflicts both large and small, interpersonal, emotional, physical, at home, at work, and within ourselves. If we look closely within ourselves we will find that the seed&nbsp;of these conflicts lies&nbsp;within ourselves; within our thoughts and actions.</p>
    <p>How can we be less argumentative, stubborn, or demanding? What’s the real reason these petty squabbles and power struggles take place? How can we be more compassionate, and caring? How can we make a positive difference to other people’s lives?</p><p>One of the philosophies of Buddhism is&nbsp;that resistance cause pain; That our resistance to living fully within the moment, our resistance to change is what causes pain. So to transcend the pain of living, Buddhism advocates letting go of the desire to be somewhere else, or have something else. To live fully within the moment, accepting ourselves and those arounds us,  as they are.</p>
    <p>By practicing and learning how to physically go around resistance and attacks (conflict) we can begin to learn about how we can resolve conflicts that are emotional or verbal.</p>
    <h2>Hang on, you’re meant to be talking about Aikido, not Buddhism</h2>
    <p>Yes, I know. But they are both related. The founder of Aikido Morihei Ueshiba was influenced by many philosophies such as Shinto, the Omoto Cult, Buddhism as well as&nbsp;&nbsp;studying many martial arts, before synthesising&nbsp;his own art, which he called Aikido.</p>
    
    <h2>What does the word “Aikido” mean?</h2>
    <ol>
      <li>Ai (合) – Universe/Harmony</li>
      <li>Ki &nbsp;(気) – Energy</li>
      <li>Do (道) – Way</li>
    </ol>

    <h3>Aikido = the way of harmonising energy</h3>
    <p>It sounds lofty, doesn’t it? And the ultimate aim of the art is to achieve harmony with the world and with our fellow beings on this planet Earth. The Founder’s aim was to create love and harmony between the peoples of this small planet; to create world peace. However, it’s a long path, paved with hard training, good friends and many lessons.</p>
    
    <h2>What techniques do you do in Aikido?</h2>
    <p>We practice a wide range of joint locks, pinning and throws from a wide range of attacks, including striking and grabbing.</p>
  
    <h2>But wait...can I win an MMA match with Aikido?</h2>
    <p>Well, the answer is "no". But we have to ask why you want to win MMA matches, anyway. If you want to win MMA matches, then training MMA would be 
    a sensible thing to do.</p>
    
    <h2>Look</h2> We live in the <a href="https://slides.ourworldindata.org/war-and-violence/#/title-slide" target="_blank">most peacful time in history</a>. 
    So unless you go around looking for fights, Aikido will be a positive force for good in your life.</p>
    <p><a href="/about/faq#IsAikidoaneffectivemartialart">I've written a longer FAQ on this topic</a></p>
    
    <h2>Aikido is what the world needs right now.</h2>
    
    <p>We are living through the 6th great mass extinction. The environmental collapse of this planet is being driven by
    egos and greed and the need to prove that "I am better than the Smiths".</p>
    
    <p>What the world needs is collaboration and cooperation. You can't collaborate if you are trying to prove that you are better than the people you are working or living with. Because to prove you are better you must put them down to make yourself feel better.</p>
    
    <p>How does it feel when someone demeans you, or puts you down?</p>
    
    <p>Wouldn't it be better if we could all just get along and support each other?</p>
    
    <p><strong>^^ This</strong> is what Aikido teaches</p>

    <h2>Aikido provides a physical and philosophical framework to improve ourselves and create a more harmonious and peaceful society.</h2>
   `},
  {
    title: 'Aikido Terms',
    path: '/glossary-aikido-terms',
    image: 'aikido-japanese-woodblock-woman.jpg',
    description: 'Aikido terms explained.',
    content: '<dl><dt id="aikido">Aikido</dt><dd>The way or harmonising energy; The art of peace.</dd><dt id="domo-arigato">Domo arigato</dt><dd>Thankyou</dd><dt id="domo-arigato-gozaimashita">Domo arigato gozaimashita</dt><dd>Thankyou very much (polite version). Said at the end of training to your training partners</dd><dt id="kiai">Kiai</dt><dd>An energetic “shout” performed by martial artists when performing a technique. Used to enhance the effectiveness of the technique. It can also have the added benefit of startling or disorienting your attacker</dd><dt id="onegai-shimasu">Onegai Shimasu</dt><dd>“Please” or “Please train with me”. Said at the start of training</dd><dt id="shomen">Shomen</dt><dd>The front of the dojo, where the scroll hangs. Symbolic of the universe.</dd></dl>',
  },
  {
    title: 'Dojo Etiquette',
    path: '/aikido-maai-training-manual/dojo-etiquette',
    image: 'hokusai_waves_narrow.jpg',
    description: 'Aikido etiquette explained.',
    content: `
    <h2>Rules of behavior</h2>
    <p>In an Aikido dojo, the observation of basic forms of etiquette is integral to the creation of a respectful and attentive atmosphere which is conductive to learning. Although Japanese forms are unfamiliar to most Westerners, over a period of time they not only become comfortable expressions of curtesy, but also, as we come to understand the levels of meaning behind the forms, they enrich and further our practice. Most basic to these forms is the bow, a gesture of respect and gratitude. If students follow the dojo etiquette they will realize that behind it lies a far deeper meaning than first seems apparent. The humanity and respect needed to practice Aikido encourages a growing sensitivity to our environment and interaction with others.</p>
    
    <p>Aikido is a living, growing Budo designed for the modern world. The physical aspects of Aikido, as awesome and beautiful as they can be, are only the outward manifestation of what is most importantly a real and direct way to improve the quality of our lives.</p>
    
    <h2>In the dojo, please observe the following guidelines:</h2>
    <ul>
      <li>Arrive at least fifteen minutes early for class, change smartly and help prepare the dojo (the way place),&nbsp;to help put down the mat, clean them, and do any other jobs required.</li>
      <li>Students not training on the mat (but watching the class instead) should be quiet. If asked questions by interested visitors answer them quietly, accurately and with respect, but not at length.</li>
      <li>A standing bow is done when entering and leaving the dojo.</li>
      <li>The formal bow at the beginning and end of practice consist of 2 seated bows, 2 claps, followed by 1 bow, towards the Shomen (front wall of the dojo where O-Sensei’s picture is). The form and complexity of this ceremony marking the beginning and end of each practice varies somewhat from place to place, so in the case of a visiting instructor, his or her example should be followed.</li>
      <li>Real effort should be made to be on time for class, but if unavoidably late, wait for the instructor’s direction.</li>
      <li>Onegai shimasu (“please,” “I ask a favor,” or “I request”) and arigato gozaimashita (“thank you”) are the expressions used at the beginning and the end of practice, and so between partners at the beginning and the end of class, respectively.</li>
      <li>The chief instructor is addressed as Sensei in the dojo and any other time and place. Yudansha should be called by their given name followed by Sempai. All kohai (junior students) should be addressed by their given names followed by San.</li>
      <li>Gi (training uniform) should be kept cleaned and washed regularly.</li>
      <li>Fingernails and toenails should be kept short for the protection of training partners.</li>
      <li>The proper way to sit during class is in seiza – the formal Japanese sitting posture. A cross-legged sitting position is acceptable if seiza is impossible.</li>
      <li>Students should not sit with their back to Sensei or toward the Shomen (wall with the picture of O-Sensei).</li>
      <li>If it is necessary to pass by people when they are lined up in seiza facing the Shomen, walk behind them.</li>
      <li>After the instructor demonstrate the first technique, students bow, chose a partner quickly, and begin practice. When the end of a particular practice is signaled (often with a clap or the word “tsugi,” meaning next) students should stop practice immediately, bow (standing) to their partners, and sit quickly in seiza.</li>
      <li>For safety reasons, respect and courtesy, it is essential that the teacher’s instructions be followed exactly. Many Aikido techniques can be dangerous if not practiced properly. Emphasis should be placed on learning as much as possible through intent observation and concentrated practice.</li>
      <li>Do not use alcohol or drugs before class.</li>
      <li>The mat should be swept before and after each class. The dojo should be kept spotless.</li>
    </ul>
    <p>
    <em>Borrowed with permission and respect from <a title="Aikido by the Bay" href="http://aikidobythebay.com/" target="_blank">Aikido By the Bay</a>, who in turn borrowed this from our sensei <a title="Martial: Field Aikido" href="http://martial.com.au" target="_blank">Michael Field</a></em></p>
    `
  },
  {
    title: 'LGBTIQ',
    path: '/lgbtiq-safe-martial-arts',
    image: 'aikido-maaai-lgbtiq-safe-space.jpg',
    description: 'Aikido Maai provides a safe space for LGBTIQ people of any age to train.',
    content: `<h2>Aikido Maai provides a safe place for people who identify as LGBTIQ</h2>
      <p>LGBTIQ people are at a higher risk of being assaulted, as well as at higher risk of mental health problems.</p>
      <p>Training aikido helps to mitigate both of these risks.</p>
      <p>Sensei Ajax believes in positive consent, is an out and proud gay man, who will always respect your boundaries. Aikido is a very hands-on martial art. We lend each other our bodies to practice. We commit to repecting each other's autonomy, and respect each other's consent to have techniques practiced upon each other.</p>
      <h3>Regular aikido training:</h3>
      <ul>
        <li>Helps improve self awareness</li>
        <li>Helps improve mindfulness</li>
        <li>Teaches and fosters situational awareness</li>
        <li>Improves coordination</li>
        <li>Improves self confidence</li>
        <li>Teaches you effective stategies to de-escalate situations before they become violent</li>
        <li>Teaches you effective techniques to deflect and defer attacks that help you avoid getting injured and applies minimal damage to your attacker</li>
        <li>Teaches control and pinning techniques that do not require strength. Smaller people can effectively control larger opponents</li>
        <li>Teaches defense against multiple attackers</li>
      </ul>
      <p>At Aikido Maai we provide a safe and inclusive space for anyone, and everyone, to train. We have a zero tolerance policy toward homophbia, transphobia, misogyny, racism or any form of exclusion.</p>
      <p>At any time, if something makes you uncomfortable, you do not have to do the technique, or training exercise.</p>
    `,
  },
  {
    title: 'Terms of training',
    path: '/aikido-maai-training-manual/terms-conditions-training',
    description: 'The terms of training at Aikido Maai - attitude, behaviour and respect and training fees.',
    content: `
      <h2>Attitude, behaviour and respect</h2>
      <p>Aikido is a modern Japanese martial art, as such we incorporate many aspects of Japanese culture into training. This means cultivating a positive and respectful attitude.</p>
      
      <p>All students (including children) are required to read the <a title="Dojo etiquette" href="/aikido-maai-training-manual/dojo-etiquette">Dojo etiquette page</a>, and abide by it. If a student repeatedly transgresses these rules, their training will be terminated, and no refunds will be given.</p>
      <h2>Training Fees</h2>
      <p>Your training fees help keep the dojo open and available for you and your children to train. Please show respect to the dojo and pay your fees on time.</p>
      <h3>Children's training fees</h3>
      <ol>
        <li>We devote our time to teaching your children Aikido, not chasing you for payment. Please make your payments prompt and timely.</li>
        <li>Training fees are due on a term by term basis, due before the start of each term's first class. <a title="Pay your fees online" href="/shop">You can pay your fees online</a>, or in cash.</li>
        <li>If your child joins in the middle of term you will need to pay for the remained of the the term, less the free class that your child attended.</li>
        <li>Unfortunately we are unable to provide make up classes if your child misses a class for any reason, fees are still due for the missed classes.</li>
      </ol>
      <h3>Adult and student training fees</h3>
      <ol>
        <li>Fees are due on the first of the month. <a title="Pay your fees online" href="/shop">Sign up and pay online to automate this process.</a></li>
        <li>You can train all available classes during this month, for your fees.</li>
        <li>If you are unable to train for one to several weeks due to illness or holiday, your fees are still due, to support the continued running of the dojo.</li>
        <li>If you are expecting to be away for a longer period of time, then you must talk with Sensei about it.</li>
      </ol>
      <h2>Updates to Term and Conditions</h2>
      <p>These terms and conditions may be updated at our discretion.</p>
    `,
  },
  {
    title: 'COVID-19',
    path: '/covid-19',
    description: 'Open handed classes Mondays and Wednesdays, Mt Waverley. Weapons classes Tuesdays & Saturdays, Ringwood',
    content: `<h2>Open handed classes Mondays and Wednesdays, Mt Waverley. Weapons classes Tuesdays & Saturdays, Ringwood</h2>`,
  }
];