import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Error.css';

class Error extends React.Component {
  render() {
    const { error, errorMessage } = this.props;
    if ( error ) {
      return (
        <div className="error-container">
          <FontAwesomeIcon icon="exclamation-triangle" />
          <span className="text">{errorMessage}</span>
        </div>
      );
    }
    return null;
  }
}

export default Error;