import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './RadioButtons.css';

class RadioButtons extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      options: props.options ? props.options : [],
    }
  }

  /**
   * Select by clicking on label
   */
  select = (value) => {
    const { name } = this.props;
    const { options } = this.state;

    for (var i=0; i<options.length; i++) {
      options[i].selected = options[i].value === value ? true : false;
    }

    this.props.setValues(name, value, true);

    this.setState({
      options: options
    });
  }

  /**
   * onChange
   * 
   * @param object e the synthetic event
   */
  onChange = (e) => {
    const { name, value } = e.target.value
    this.props.setValues(name, value, true);
  }

  renderButtons = () => {
    const { options } = this.state;

    let buttons = [];
    for (let i=0; i<options.length; i++) {
      const button = options[i];
      const icon = button.selected ? <FontAwesomeIcon icon="check-circle" /> : <div className="circle" />
      
      buttons.push(
        <div key={i} className="radio-button" onClick={() => this.select(button.value)}>
          <input
            type="radio"
            name={button.name}
            value={button.value}
            onChange={this.onChange}
            selected={button.selected} />
            {icon}
          <label htmlFor={button.name}>{button.label}</label>
        </div>
      );
    }
    return buttons;
  }

  render() {
    const { label, required } = this.props;
    return (
      <div className="field radio-buttons">
        <div className="label">
          {label}
          {required ? <span className="required">*</span> : null}
        </div>
        {this.renderButtons()}
      </div>
    );
  }
}

export default RadioButtons;
