export const OPEN = false;

export const FEATURE = {
  text: 'Dojo closed',
  link: '/'
};

export const PROD_URL = 'https://aikidomaai.com/';

export const IMAGE_ROOT = 'https://aikidomaai-media.s3.amazonaws.com/';

export const SHOP_TRAIL = [
  {
    to: '/shop',
    text: 'Shop',
  },
  {
    to: '/cart',
    text: 'Cart',
  },
  {
    to: '/checkout',
    text: 'Checkout',
  },
];

// Regex
export const EMAIL_REGEX = new RegExp(/.+@.+\..+/i);
export const MOBILE_REGEX = new RegExp(/((\+61)*\s*(0)*(4|5)[0-9\s]{8,10})|(^[0-9\s*]{8,12}$)/);
export const ZIP_REGEX = new RegExp(/[0-9]{1,4}/);

// Is this dev environment?
export const IS_DEV = new RegExp(/localhost|dev/);
export const DEV_ENV = IS_DEV.test(window.location.href) ? true : false;

// AWS, SMS, Email & Slack Urls; Stripe public keys
const  AWS = 'https://api.aikidomaai.com';

const EMAIL_URL_DEV = `${AWS}/dev/email`;
const EMAIL_URL_PROD= `${AWS}/v1/email`;

const SMS_URL_DEV = `${AWS}/dev/sms`;
const SMS_URL_PROD = `${AWS}/v1/sms`;

const CHARGE_URL_DEV = `${AWS}/dev/charge`;
const CHARGE_URL_PROD = `${AWS}/v1/charge`;

const SUBSCRIBE_URL_DEV = `${AWS}/dev/subscribe`;
const SUBSCRIBE_URL_PROD = `${AWS}/v1/subscribe`;

const STRIPE_TEST_KEY = 'pk_test_f7iLMb9yWop4IscS2W5eES3I';
const STRIPE_LIVE_KEY = 'pk_live_Q5MXsJT9u2LrTHB0Qb2U6mcr';

export const EMAIL_URL = DEV_ENV ? EMAIL_URL_DEV : EMAIL_URL_PROD;
export const SMS_URL = DEV_ENV ? SMS_URL_DEV : SMS_URL_PROD;
export const CHARGE_URL = DEV_ENV ? CHARGE_URL_DEV : CHARGE_URL_PROD;
export const SUBSCRIPTION_URL = DEV_ENV ? SUBSCRIBE_URL_DEV : SUBSCRIBE_URL_PROD;
export const STRIPE_KEY = DEV_ENV ? STRIPE_TEST_KEY : STRIPE_LIVE_KEY;

// Slack
const SLACK_URL = 'https://hooks.slack.com/services/TM01G2WE9';
export const SLACK_SALES = `${SLACK_URL}/BM25GSKQE/5GYh1qSmhoDkV1D5oPXwNEtN`;
export const SLACK_CONTACT = `${SLACK_URL}/BLNJVRX6X/4L7amSKJzpZT4YHm5WPXLnsy`;
export const SLACK_CHECKIN = `${SLACK_URL}/B01E6PS23EZ/XWAwQkhoaB4wHLQzxrUHGE5e`;
export const SLACK_FREE_CLASSES = `${SLACK_URL}/BM01K57RB/673JKtQG3d5wgx6wKkSpZGXG`;
export const SLACK_TESTING = `${SLACK_URL}/BPRTY49SS/tICVjqiV2DhQIq8JgVE0LEUp`;
export const SLACK_ERRORS = `${SLACK_URL}/BPW98DB2A/66SvZsesICl6kkUp1XI5u1Ig`;

// Admin email and name
export const ADMIN_EMAIL = 'admin@aikidomaai.com';
export const ADMIN_NAME = 'Ajax Sensei';

// Checkout:
export const DEFAULT_CHECKOUT_FIELDS = {
  card: {
    valid: null
  },
  childName: {
    required: false,
    value: '',
    valid: null
  },
  cvc: {
    valid: null
  },
  customerID: {
    required: false,
    value: '',
    valid: null,
  },
  email: {
    required: true,
    value:'',
    valid: null
  },
  expiry: {
    valid: null
  },
  name: {
    required: true,
    value: '',
    valid: null
  },
  mobile: {
    required: true,
    value: '',
    valid: null,
  },
  street: {
    required: true,
    value: '',
    valid: null
  },
  suburb: {
    required: true,
    value: '',
    valid: null
  },
  zip: {
    required: true,
    value: '',
    valid: null
  },
};

export const EMPTY_BASKET = {
  items: [],
  fixedTotal: 0,
  subscriptionTotal: 0
};

export const PLACES = [
  'mt-waverley',
  'glen-waverley',
  'ashwood',
  'burwood',
  'burwood-east',
  'vermont',
  'vermont-south',
  'ashburton',
  'deakin-university',
  'monash-university',
  'wesley-college',
  'presbyterian-ladies-college',
  'chadstone',
  'nunawading',
  'oakleigh',
  'oakleigh-east',
  'huntingdale',
  'hughesdale',
  'murrumbeenah',
  'clayton',
  'clayton-south',
  'springvale',
  'mulgrave',
  'wheelers-hill',
  'forest-hill',
  'blackburn',
  'blackburn-south',
  'box-hill',
  'box-hill-south',
  'surrey-hills',
  'camberwell',
  'ringwood',
  'ringwood-east',
  'mitcham',
  'nunawading',
  'blackburn',
  'blackburn-south',
  'croydon',
  'donvale',
  'wantirna'
];

export const COVID_SAFE_LIST =`
<li><a href="https://www.coronavirus.vic.gov.au/how-we-live#sport-and-recreation" target="_blank" rel="noopener noreferrer">COVID Restrictions are in place</a></li>
<li>You must be double vaccinated and have proof via digital certificate, or printed vaccination history from your vaccination provider</li>
<li>All students will need to check in using QR code at venue</li>
<li>Do not come to training if you are feeling unwell.</li>
<li>Bring your own water bottle - don't use cups in the hall</li>
<li>Please make use of hand sanitizer before and after class</li>
<li>Mats to be wiped down before each class with warm water with methylated spirits and eucalyptus oil</li>
<li>We will wipe down surfaces used for placing water bottles, door knobs, etc after class</li>
`;

export const DAYS = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7
};
