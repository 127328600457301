import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
import ImageHeading from '../../../components/image/ImageHeading';
import { SHOP_TRAIL } from "../../../data/Data";
import Basket from './Basket';

const IMAGE = 'aikido-maaai-checkout.jpg';

class Cart extends React.Component {


  render() {
    const { basket } = this.props;
    const trail = [...SHOP_TRAIL];
    trail.splice(trail.length-1,1);

    return (
      <div className="cart">
        <ImageHeading title="Cart" image={IMAGE} />
        <section>
          <Breadcrumbs {...this.props} trail={trail} />
          <Basket {...this.props} />
          {
            (basket.items.length > 0 )
              ?  <Link
                  to="/checkout"
                  className="button checkout">
                    Checkout now <FontAwesomeIcon icon="chevron-right" />
                </Link>
              : null
          }
        </section>
      </div>
    );
  }
}

export default Cart ;
