import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Breadcrumbs.css';


class Breadcrumbs extends React.Component {
  
  render() {
    const { trail } = this.props;

    if (trail.length === 0 ) {
      return null;
    }

    let breadcrumbs = [];

    for (let i=0; i<trail.length; i++) {
      const to = trail[i].to;
      const text = trail[i].text

      if ( i === trail.length - 1 ) {
        breadcrumbs.push(
          <span className="last" key={i}>
            {text}
          </span>
        );
      }
      else {
        breadcrumbs.push(
          <React.Fragment key={i}>
            <Link to={to}>{text}</Link>
            <FontAwesomeIcon icon="chevron-right" />
          </React.Fragment>
        );
      }
     
    }

    return (
      <div className="breadcrumbs">
        {breadcrumbs}
      </div>
    );  
  }
}

Breadcrumbs.propTypes = {
  trail: PropTypes.array
}

Breadcrumbs.defaultProps = {
  trail: []
}

export default Breadcrumbs ;
