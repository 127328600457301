import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { IMAGE_ROOT } from '../../../data/Data';
import './Basket.css';

export default class Basket extends React.Component {

  /**
   * Change the amount of product
   * This propogates up to App.js
   * 
   * @param product {object} the product to be incremented or decremented
   * @param action {string} add or remove a product
   *  
   */
  alterProduct = (product, action) => {
    const mutate = {
      action,
      product
    }

    this.props.mutateBasket(mutate);
  }

  proRateSub = () => {
    return 0;
  }

  render() {
    const { basket } = this.props;
    const items = [ ...basket.items ];
    let cart = [];
    let rows = [];
    const now = moment();
    const nextMonth = now.add(1, 'month').format('MMMM');
    const year = now.add(1, 'month').format('YYYY');

    if (items.length === 0) {
      return <EmptyBasket />;
    }

    for (let i=0; i<items.length; i++) {
      const item = items[i];
      let {
        image,
        name,
        price,
        proRated,
        quantity,
        subscription,
        weeks,
      } = item;

      let rowPrice = price;

      // is this a subscription product?
      let description = <span className="description">{weeks ? `${weeks} weeks `: ''} @ ${rowPrice.toFixed(2)}</span>;
      
      if (subscription) {
        description = <span className="description">You will be charged ${price * quantity} on 1 {nextMonth} {year}</span>;
        rowPrice = 0;
      }

      // Make the row
      rows.push(
        <div key={i} className={`row${subscription ? ' subscription': ''}`}>
          <div className="quantity">
            <div className="value">{quantity}</div>
            {
              !proRated
                ? <React.Fragment>
                    <div className="alter plus" onClick={() => this.alterProduct(item, 'add')}>
                      <FontAwesomeIcon icon="plus" />
                    </div>
                    <div className="alter minus" onClick={() => this.alterProduct(item, 'remove')}>
                      <FontAwesomeIcon icon="minus" />
                    </div>
                  </React.Fragment>
                : null
            }
          </div>
          <div className="image">
            <img src={`${IMAGE_ROOT}${image}`} alt={name} />
          </div>
          <div className="details">
            <span className="name">
              {name}
            </span>
            {description} 
          </div>
          <div className="price">
            ${(rowPrice * quantity).toFixed(2)}
          </div>
        </div>
      )
    }
    cart.push(<div key="rows" className="row-container">{rows}</div>);

    // Totals
    cart.push(
      <div key="totals" className="totes">
        <div className="sub-total total-row">
          <span className="label">Sub total</span> 
          <span className="value">${basket.fixedTotal}</span> 
        </div>
        <div className="gst total-row">
          <span className="label">GST<sup>*</sup></span> 
          <span className="value">$0.00</span> 
          
        </div>
        <div className="grand-total total-row">
          <span className="label">Total</span> 
          <span className="value">${basket.fixedTotal}</span> 
        </div>
      </div>
    )

    // GST notice
    cart.push(
      <p key="no-gst" className="no-gst small clear right">*Aikido Maai is GST exempt</p>
    )

    return (
      <div className="basket">
        {cart}
      </div>
    );
  }

}

export class EmptyBasket extends React.Component {
  render() {
    return (
      <div className="empty">
        <p>Your basket is like the Zen mind.</p>
        <Link className="button" to="/shop">Shop now</Link>
      </div>
    );
  }
}
