/**
 * Location
 * 
 * @flow
 */
import React from 'react';
import {Helmet} from 'react-helmet';
import ImageHeading from '../image/ImageHeading';
import Address from '../address/Address';
import './Location.css';

const IMAGE = 'aikido-maai-location.jpg';

type Props = {
  embed: boolean,
};

class Location extends React.Component<Props> {
  static defaultProps = {
    embed: false,
  };

  render() {
    const {embed} = this.props;
    const description = "Aikido Maai: martial arts";
    return (
      <div className="location">
        {
          !embed
            ? (
              <Helmet>
                <title>Location</title>
                <meta name="description" content={description} />
              </Helmet>
            )
            : null          
        }
        <ImageHeading {...this.props} title="Location" image={IMAGE} />
        <section>
          <Address />
          {/* <DojoMap /> */}
        </section>
       
      </div>
    );
  }
}

export default Location;
