import React from 'react';
import './Syllabus.css';

class DanRankRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0
    }
  }

  onChange = (value) => {
    const { onChange, pos } = this.props;
    this.setState({
      value: value
    });

    onChange(value, pos);
  }

  renderNumbers = () => {
    const { value } = this.state;
    const stringClass = ['zero', 'one', 'two', 'three', 'four', 'five'];
    let numbers = [];
    for (let i=1; i<6; i++) {

      numbers.push(
      <div
        key={i}
        className={`number${i === value ? ' selected' : ''} ${stringClass[i]}`}
        onClick={() => this.onChange(i)}>
        {i}
      </div>
      );
    }
    return numbers;
  }

  render() {
    const {
      value
    } = this.state;

    const {
      attacks,
      grade,
      title,
      variations
    } = this.props;

    const values = [
      'no rating',
      'poor',
      'below level',
      'at level',
      'above level',
      'excellent'
    ];

    return (
      <div className={`dan-rank-row ${grade ? ' grade' : ''}`}>
        <div className="label">{title}</div>
        <div className="content">
          {
            attacks
              ? <div className="attacks"><span className="label-a">Attacks</span><span className="content">{attacks.join(', ')}</span></div>
              : null
          }
          {
            variations
              ? <div className="variations"><span className="label-a">Variations</span><span className="content">{variations.join(' & ')}</span></div>
              : null
          }
        </div>
        {
          grade
            ? (
                <div>
                  <div className="score">
                    {this.renderNumbers()}
                  </div>
                  <div className={`comment${value === 0 ? ' no-grade' : ''}`}>{values[value]}</div>
                </div>
              )
            : null
        }
      </div>
    );
  }
}

export default DanRankRow;
