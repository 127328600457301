import {PROD_URL} from '../../data/Data';
import {PRODUCTS} from '../../pages/shop/Products.data';

export const REVIEWS = [
  {
    author: 'Steven Khuu',
    reviewBody: 'Great Dojo, Very friendly and fun.',
    reviewRating:  {
      '@type': 'Rating',
      ratingValue: 5,
      bestRating: 5
    },
    itemReviewed: {
      name: PRODUCTS[1].name,
      alternativeName: 'Adults / teens aikido',
      url: `${PROD_URL}#${PRODUCTS[0].id}`,
      slug: '/learn-aikido'
    }
  },
  {
    author: 'Van Khuu',
    reviewBody: 'I have been training in this dojo for many years and enjoy it very much. Ajax sensei is a great instructor, his teaching methods is friendly, clear and effective. I Highly recommend the dojo to anyone want to learn Aikido.',
    reviewRating: {
      '@type': 'Rating',
      ratingValue: 5,
      bestRating: 5
    },
    itemReviewed: {
      name: PRODUCTS[0].name,
      alternativeName: 'Adults / teens aikido',
      url: `${PROD_URL}#${PRODUCTS[0].id}`,
      slug: '/learn-aikido'
    }
  },
  {
    author: 'Pierce Russon',
    reviewBody: "It's good exercise and the games are fun!",
    reviewRating: {
      '@type': 'Rating',
      ratingValue: 5,
      bestRating: 5
    },
    itemReviewed: {
      name: "Children's Aikido classes",
      alternativeName: "Kid's aikido",
      url: `${PROD_URL}#childrens-aikido-classes`,
      slug: '/childrens-aikido-classes',
    }
  },
  {
    author: 'Tuan Hoang',
    reviewRating: {
      '@type': 'Rating',
      ratingValue: 4,
      bestRating: 5
    },
    itemReviewed: {
      name: PRODUCTS[0].name,
      alternativeName: 'Adults / teens aikido',
      url: `${PROD_URL}#${PRODUCTS[0].id}`,
      slug: '/learn-aikido'
    }
  },
  {
    author: 'Leigh Hanney',
    reviewBody: "I started training Aikido again after a ~10 year break. Loving it! Aikido Maai is a friendly, welcoming, high quality dojo. Recommended to all.",
    reviewRating: {
      '@type': 'Rating',
      ratingValue: 5,
      bestRating: 5
    },
    itemReviewed: {
      name: PRODUCTS[0].name,
      alternativeName: 'Adults / teens aikido',
      url: `${PROD_URL}#${PRODUCTS[0].id}`,
      slug: '/learn-aikido'
    }
  },
  {
    author: 'Seikatsu Aikido',
    reviewBody: "Ajax sensei is a dedicated aikido practitioner from a strong pedigree. I can recommend him for Aikido tuition.",
    reviewRating: {
      '@type': 'Rating',
      ratingValue: 5,
      bestRating: 5
    },
    itemReviewed: {
      name: PRODUCTS[0].name,
      alternativeName: 'Adults / teens aikido',
      url: `${PROD_URL}#${PRODUCTS[0].id}`,
      slug: '/learn-aikido'
    }
  }
  
];