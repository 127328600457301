/**
 * Text Area
 * @flow
 */

import React from 'react';
import Error from '../error/Error';
import Label from '../label/Label';
import fn from '../../functions/Functions';
import type { localforage } from 'localforage';
import type { BugSnag } from '@bugsnag/js';
import type { FormType } from '../../types/Types';
import './Textarea.css';

type Props = {
  bugsnagClient: BugSnag,
  conditional: boolean,
  conditions: {
    field: string,
    name: string,
    value: boolean
  },
  className: string,
  form: FormType,
  label: string,
  localforage: localforage,
  name: string,
  setValues: (name: string, value: string, valid: boolean | null) => *,
  type: string,
  placeholder: string,
  required: boolean,
  valid: boolean | null,
};

type State = {
  error: boolean,
  errorMessage: string,
  valid: boolean | null,
  value: string,
}

class Textarea extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      error: false,
      errorMessage: '',
      valid: null,
      value: '',
    }
  }

  /**
   * onChange
   */
  onChange = (e: SyntheticEvent<>) => {
    this.validate(e)
  }

  /**
   * onBlur
   */
  onBlur = (e: SyntheticEvent<>) => {
    this.validate(e)
  }

  /**
   * Validate
   */
  validate(e: SyntheticEvent<>) {
    const { label, name, required,  setValues} = this.props;
    // $FlowFixMe
    const { value } = e.target;

    const valid = (required && (value === undefined || value.length === 0)) ? false : true;
    const errorMessage = !valid ? `${label}  is required` : '';

    this.setState({
      error: !valid,
      errorMessage,
      value,
      valid,
    }, () => setValues(name, value, valid));
  }
  
  render() {
    const { conditional, conditions, form, name, placeholder, required } = this.props;
    const {error, errorMessage, valid, value } = this.state;
    let show = '';
    
    if (conditional) {
      show = fn.showField(conditional, conditions, form) ? '' : ' hidden';
    }

    return (
      <div className={`field textarea${show}`}>
        <Label {...this.props} />
        <textarea
          className={`${(valid === false) ? 'invalid' : ''}`}
          name={name}
          onChange={this.onChange}
          placeholder={placeholder}
          onBlur={this.onBlur}
          value={value}
          required={required}
        />
        <Error error={error} errorMessage={errorMessage} />
      </div>
    );
  }
}

export default Textarea;