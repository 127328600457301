/**
 * Submitting
 * 
 * @flow
 */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Submitting.css';

type Props = {
  submitting: boolean,
};

class Submitting extends React.Component<Props> {
  render() {
    const {submitting} = this.props;

    if (submitting) {
      return (
        <div className="sending">
          <FontAwesomeIcon icon="spinner" className="spinner" />
          <span className="text">Sending...</span>
        </div>
      );
    }
    return null;
  }
}

export default Submitting;
