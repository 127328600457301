/**
 * Main
 * 
 * @flow
 */
import React from 'react';
import {Helmet} from 'react-helmet';
import {BrowserRouter} from 'react-router-dom';
import moment from 'moment';
import Header from '../header/Header';
import Alert from '../alert/Alert';
import Nav from '../nav/Nav';
import Routes from '../../route/Routes';
import Footer from '../footer/Footer';
import { EMPTY_BASKET, DEV_ENV } from '../../data/Data';
import type {BasketType, Items, Mutate, Product, Sku} from '../../types/Types';
import type BugsnagClient from '@bugsnag/js';
import type LocalForage from 'localforage';
import type Moment from 'moment';

type Props = {
  bugsnagClient: BugsnagClient,
  localforage: LocalForage,
};

type State = {
  basket: BasketType,
  query: string,
};

class Main extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const basket = { ...EMPTY_BASKET };

    this.state = {
      basket,
      query: '',
    }
  }

  componentDidMount() {
    const { localforage, bugsnagClient } = this.props;

    if (!localforage) return null;

    localforage.getItem('basket')
      .then (
        (basket) =>  {
          if (!basket) return null; 
          
          this.setState({
            basket
          });
        }
      )
      .catch(
        (error) => bugsnagClient.notify(error)
      )
  }

  /**
   * Search term
   * 
   * @param {string} query
   */
  search = (query: string) => {
    this.setState({
      query: query
    });
    return null;
  }

  /**
   * Calculate the fixed total of products
   * 
   * @param {array} items - the items in the basket
   */
  fixedTotal = (items: Items) => {
    let fixedTotal = 0;
    for (let i=0; i<items.length; i++) {
      const item = items[i];
      if (!item.subscription && item.quantity) {
        fixedTotal += item.price * item.quantity;
      }
    }
    return fixedTotal;
  }

  /**
   * Calculate the subscription total of products
   * 
   * @param {array} items - the items in the basket
   */
  subscriptionTotal = (items: Items) => {
    let subscriptionTotal = 0;
    for (let i=0; i<items.length; i++) {
      const item = items[i];
      if (item.subscription && item.quantity) {
        subscriptionTotal += item.price * item.quantity;
      }
    }
    return subscriptionTotal;
  }

  /**
   * Create a fixed prorated product for a subscription
   * 
   * For the moment we presume that there are 4 weeks in the month
   */
  proRateSubscription = (product: Product, now: Moment) => {
    // if #npr in URL then don't add a pro rated product 
    if ('#npr' === window.location.hash) {
      return null;
    }

    const date = now.date();
    const month = now.format('MMMM');
    const year = now.year();
    const week = Math.floor(date/7);
    const remaining = 4 - week;
    if (remaining === 0) {
      return false;
    };
    const proRata = remaining/4 * product.price;
    const linkedSku = DEV_ENV ? product.sku.dev : product.sku.prod;
    const name = `Pro-rated training (${product.name}) fee for ${remaining} ${remaining ===  1 ? 'week' : 'weeks'} of ${month} ${year}`;
    const proRataProduct = {
      name,
      alternateName: product.alternateName,
      description: product.description,
      brand: product.brand,
      tags: product.tags,
      id: `pro-rata-${product.id}`,
      sku: { 
        dev: `pro-rata-${product.sku.dev}`,
        prod: `pro-rata-${product.sku.prod}`
      },
      price: proRata,
      quantity: product.quantity,
      subscription: false,
      proRated: true,
      linkedSku,
      image: product.image,
    }
    return proRataProduct;
  }


  /**
   * Update basket
   * 
   * @param {object} mutate - the action and product to mutate the basket with
   * @returns {undefined} - updates state.basket
   */
  mutateBasket = (mutate: Mutate, now: Moment = moment() ) => {
    const { localforage } =  this.props;
    let { basket } = this.state;
    const { action, product } = mutate;
    const index = this.inBasket(product, basket);
    let proRatedProduct = null;
    let proRatedIndex = false;
    const proRataSku = {
      dev: `pro-rata-${product.sku.dev}`,
      prod: `pro-rata-${product.sku.prod}`
    };
    let quantity = (index !== -1) ? basket.items[index].quantity : 0;

    switch(true) {
      // Not in basket - add
      case (index === -1 && action === 'add'):
        product.quantity = 1;
        basket.items.push(product);

        if (product.subscription) {
          proRatedProduct = this.proRateSubscription(product, now);
          if (proRatedProduct) {
            basket.items.push(proRatedProduct);
          }
          basket.subscriptionTotal = this.subscriptionTotal(basket.items);
        }

        basket.fixedTotal = this.fixedTotal(basket.items);
        break;

      // Already in basket and add another
      case(index !== -1 && action === 'add'):
        ++quantity;
        // $FlowFixMe
        basket.items[index].quantity = quantity;

        if (product.subscription) {
          proRatedIndex = this.inBasket({sku: proRataSku}, basket);
          if (proRatedIndex) {
            basket.items[proRatedIndex].quantity = quantity;
          }
          basket.subscriptionTotal = this.subscriptionTotal(basket.items);
        }

        basket.fixedTotal = this.fixedTotal(basket.items);
        break;

      // in basket and remove single item
      case (index !== -1 && action === 'remove' && quantity === 1):
        basket.items.splice(index,1);

        if (product.subscription) {
          proRatedIndex = this.inBasket({sku: proRataSku}, basket);
          if (proRatedIndex !== false) {
            basket.items.splice(proRatedIndex, 1);
          }
          basket.subscriptionTotal = this.subscriptionTotal(basket.items);
        }

        basket.fixedTotal = this.fixedTotal(basket.items);
        break;

      // in basket and remove single item when more than 1
      case (index !== -1 && action === 'remove' && quantity > 1):
        basket.items[index].quantity = --quantity;

        if (product.subscription) {
          proRatedIndex = this.inBasket({sku: proRataSku}, basket);
          if (proRatedIndex !== false) {
            basket.items[proRatedIndex].quantity = quantity;
          }
          basket.subscriptionTotal = this.subscriptionTotal(basket.items);
        }
        
        basket.fixedTotal = this.fixedTotal(basket.items);
        break;
      
      default:
        //no op
    }

    this.setState({
      basket
    });

    // save to localforage
    localforage.setItem('basket', basket);
  }

  /**
   * Clear the basket
   */
  clearBasket = () => {
    const basket = {
      items: [],
      fixedTotal: 0,
      subscriptionTotal: 0
    };

    this.setState({
      basket
    });
    this.props.localforage.setItem('basket', basket);
  }

  /**
   * inBasket
   * 
   * Search this.state.basket by product.sku
   * 
   * @param {object} product:  the product to search for
   * @param {object} basket: the basket
   * 
   * @returns {string|boolean} the position of the product or false
   */
  inBasket = (product: Product | Sku, basket: BasketType) => {
    const { items } = basket;
    const prodSku = DEV_ENV ? product.sku.dev : product.sku.prod;
    for ( let i=0; i<items.length; i++) {
      const itemSku = DEV_ENV ? items[i].sku.dev : items[i].sku.prod;
      if (itemSku === prodSku) {
        return i;
      }
    }

    return -1;
  }

  render() {
    return (
      <BrowserRouter>
        <Helmet titleTemplate="%s - Aikido Maai" defaultTitle="Home">
          <script dangerouslyInsertHTML={{__html: DEV_ENV ? '' : `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-5LDDNNX');`}} />
        </Helmet>
        <Alert />
        <Header search={this.search} {...this.state} {...this.props} />
        <Nav {...this.state} />
        <div id="main">
          <Routes
            {...this.props}
            {...this.state}
            clearBasket={this.clearBasket}
            mutateBasket={this.mutateBasket}
            search={this.search}
          />
        </div>
        <Footer />
      </BrowserRouter>
    );
  }
}

export default Main;
