import React from 'react';
import {Helmet} from 'react-helmet';
import ImageHeading from '../image/ImageHeading';
import InterestForm from '../forms/InterestForm';
import { LANDING_PAGES } from './LandingPages.data';
import './LandingPage.css';

class LandingPage extends React.Component {

  render() {
    const content = [...LANDING_PAGES[1].content];
    const images = [
      LANDING_PAGES[1].image,
      'aikido-kids-classes-wide.jpg',
      'aikido-kids-grading-photo-2019.jpg'
    ];
    let i=0;

    const title = "Children's Aikido";
    const description = "Kids learn confidence and listeneng skills at Aikido Maai, martial arts training, Mt Waverly";
    return (
      <div className="page landing kids">
          <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
          </Helmet>
          <ImageHeading  title="Kid's Aikido" image={images[i]} />
          <section>
            <div dangerouslySetInnerHTML={{__html: content[i]}} />
            <InterestForm />
          </section>
          <ImageHeading image={images[++i]} title="Class details" embed={true} />
          <section>
            <div dangerouslySetInnerHTML={{__html: content[i]}} />
            <InterestForm />
          </section>
          <ImageHeading image={images[++i]} title="Sensei" embed={true} />
          <section>
            <div dangerouslySetInnerHTML={{__html: content[i]}} />
            <InterestForm />
          </section>
      </div>
    );
  }
}

export default LandingPage;