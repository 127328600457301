import React from 'react';
import {Helmet} from 'react-helmet';
import ImageHeading from '../../components/image/ImageHeading';
import Product from './product/Product';
import fn from '../../functions/Functions';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SHOP_IMAGE, PRODUCTS } from './Products.data';
import { SCHOOL_TERMS } from '../classes/Classes.data';
import './Shop.css';

class Shop extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: []
    }
  }

  /**
   * Generate kids classes programatically
   * 
   * @param {moment} now is a moment object
   * 
   */
  genClasses = (now) => {
    let classes = [];

    for (let i=0; i<SCHOOL_TERMS.length; i++ ) {
      const { title, start, end } = SCHOOL_TERMS[i];

      const termStart = moment(new Date(start));
      const termEnd = moment(new Date(end));
      const weeks = termEnd.diff(termStart, 'weeks'); // our terms are not full weeks
    
      switch(true) {
        // We are in term
        case(now.isAfter(termStart) && now.isBefore(termEnd)):
          classes.push(fn.createClass(title, weeks));
          break;

        // Term is about to start or has just finished
        case(now.isAfter(termStart.subtract(1, 'month')) && now.isBefore(termEnd)):
          classes.push(fn.createClass(title, weeks));
          break;

        default:
          // no op
      }
    }

    return classes;
  }

  /**
   * Generate a list of clickable tags from the products
   * 
   * @params {array} products
   * 
   * @returns {component} a react component
   */
  renderTags = (products) => {
    if (!products) {
      return null;
    }

    let tags = [];
    let printTags = [];
    const stateTags = [...this.state.tags];
    stateTags.sort();

    for (let i=0; i<products.length; i++) {
      const pTag = products[i].tags;

      for (let x=0; x<pTag.length; x++) {
        const aTag = pTag[x];
        if (tags.indexOf(aTag) === -1) {
          tags.push(aTag);
        }
      }
    }

    // Sort alphabetically
    tags.sort();

    // Print 
    for (let y=0; y<tags.length; y++) {
      const tag = tags[y];
      if (stateTags.indexOf(tag) > -1) {
        printTags.push(
          <li key={y}><button className="selected" onClick={() => this.filter(tag)} tabIndex="0">{tag}</button></li>
        );
      } else {
        printTags.push(
          <li key={y}><button onClick={() => this.filter(tag)} tabIndex="0">{tag}</button></li>
        );
      }
    }

    if (stateTags.length > 0) {
      printTags.push(
        <li key="clear"><button className="clear-tags" onClick={() => this.clearTags()}><FontAwesomeIcon icon="times" /></button></li>
      );
    }

    return <ul className="tags">{printTags}</ul>;
  }

  clearTags = () => {
    this.setState({
      tags: []
    })
  }

  /**
   * Filter the products by tag
   * 
   * @param {string} tag
   */
  filter = (tag) => {
    let { tags }  = this.state;
    const index = tags.indexOf(tag);

    switch(true) {
      case( index > -1) :
        tags.splice(index, 1);
        break;
      
      default:
        tags.push(tag);
    }
  
    this.setState({
      tags
    });
  }

  render() {
    // const now = moment();
    const classes = []; // this.genClasses(now);
    let products = classes ? [ ...classes, ...PRODUCTS ] : [ ...PRODUCTS ];
    // let products = [...PRODUCTS]; 

    let jsonLd = [];
    for (let i=0; i<products.length; i++) {
      jsonLd.push(fn.jsonLd(products[i], 'Product'));
    }

    let show = [];
    let shown = [];
    const { tags } = this.state;

    for (let i=0; i<products.length; i++) {
      const product = products[i];

      if (tags.length === 0) {
        show.push(<Product key={i} product={product} {...this.props} />);
      } else {
        for (let x=0; x<product.tags.length; x++) {
          if (tags.indexOf(product.tags[x]) > -1 && shown.indexOf(product.sku) === -1) {
            shown.push(product.sku);
            show.push(<Product key={`${i}_${x}`} product={product} {...this.props} />);
          }
        }
      }
    }

    const title = "Shop";
    const description = "Pay for training, buy gis, and weapons (wooden staff and sword).";
    return (
      <div className="Shop">
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <script type="application/ld+json">
            {JSON.stringify(jsonLd)}
          </script>
        </Helmet>
        <ImageHeading title="Shop" image={SHOP_IMAGE} />
        <section>
          <h2>Pay for open handed and weapons classes</h2>
          {this.renderTags(products)}
          <div className="products">
            {show}
          </div>
        </section>
      </div>
    );
  }
}

export default Shop;