/**
 * Search form
 * 
 * @flow
 */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SearchForm.css';

const DESKTOP = 768;

type Props = {
  header: boolean,
  history: {
    push: (location: string) => *,
  },
  search: (query: string) => *,
  showTagline: (show: boolean) => *,
  query: string,
  
};

type State = {
  results: Array<*>,
  mobile: boolean,
  header: boolean,
  query: string,
  width: number,
  height: number,
  redirect: boolean,
  show: boolean,
};

class SearchForm extends React.Component<Props, State> {
  static defaultProps = {
    header: false,
  }

  // $FlowFixMe
  searchRef: React.RefObject;

  constructor(props: Props) {
    super(props);

    // Get search term from URL
    const urlParams = new URLSearchParams(window.location.search);
    const query = urlParams.get('search');

    this.searchRef = React.createRef();

    this.state = {
      results: [],
      mobile: false,
      header: props.header ? props.header : false,
      query: query ? query : '',
      width: 0,
      height: 0,
      redirect: false,
      show: false,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions = () => {
    const width = window.innerWidth;

    this.setState({
      mobile: (width < DESKTOP) ? true : false,
      show: (width > DESKTOP) ? true : false,
      width: width,
      height: window.innerHeight
    });
  }

  /**
   * onChange
   */
  onChange = (e: SyntheticEvent<>) => {
    // $FlowFixMe
    const query = e.target.value;

    this.setState({
      query: query
    });

    this.props.search(query);
  }

  /**
   * onBlur
   */
  onBlur = (e: SyntheticEvent<>) => {
    // $FlowFixMe
    const query = e.target.value;

    this.setState({
      query: query
    });
  }

  /**
   * submit
   */
  submit = (e: SyntheticEvent<>) => {
    e.preventDefault();
    const {mobile, show, header, query} = this.state;
    const {showTagline} = this.props; 
    
    if (mobile && !show && header) {
      this.setState({
        show: true
      });
      showTagline(false);
      this.searchRef.current.focus();
      return false;
    }

    if (header) {
      this.props.search(query);
      this.props.history.push('/search');
      this.setState({
        show: false
      });
      showTagline(true);
      return 'header-search';
    }
    return true;
  }

  render() {
    const {query} = this.props;
    const { show, header, width}  = this.state;

    const id = header ? 'header-search' : 'search';
    // return search form
    return (
      <div className={`search-form ${(show && header && width < 1000) ? ' mobile-show' : '' }`}>
        <form>
          <label htmlFor="search">Search</label>
          <input
            ref={this.searchRef}
            id={id}
            className={`search${(show && header) ? ' show' : '' }`}
            type="text"
            name="search"
            placeholder="Search"
            onChange={this.onChange}
            onBlur={this.onBlur}
            value={query}
          />
          <button className="submit" onClick={this.submit}>
            <span className="search-text">Search</span>
            <FontAwesomeIcon icon="search" className="search-icon"/>
          </button>
          
        </form>
      </div>
    );
  }
}

export default SearchForm;
