/**
 * Header
 * 
 * @flow
 */
import React from 'react';
import Logo from '../logo/Logo';
import { NavLink } from 'react-router-dom';
import './Header.css';

type Props = {};
type State = {
  tagLine: boolean,
};

class Header extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tagLine: true
    }
  }

  /**
   * Show tagline
   * 
   * Used to hide tagline when searching on mobile
   */
  showTagline = (tagLine: boolean) => {
    this.setState({
      tagLine
    });
  }

  render() {
    return (
      <header>
        <NavLink to="/">
          <Logo />
        </NavLink>
      </header>
    );
  }
}

export default Header;
