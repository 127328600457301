/**
 * Calendar like dates
 * @flow
 */
import React from 'react';
import moment from 'moment';
import './CalendarDate.css';

type Props = {
  date: Date
};

const CalendarDate = (props: Props) => {
  const { date } = props;
  const day = moment(date).format('ddd');
  const time = moment(date).format('h:mma');
  const number = moment(date).format('D');
  const month = moment(date).format('MMM');

  return (
    <div className={`CalendarDate ${day.toLowerCase()}`}>
      <div className="time">
        {time}
      </div>
      <div className="day">
        {day}
      </div>
      <div className="date">
        {number}
      </div>
      <div className="month">
        {month}
      </div>
    </div>
  );
}

export default CalendarDate;
