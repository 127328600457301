import React from 'react';
import { withRouter } from 'react-router-dom';
import './Nav.css';

class Nav extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      show: false
    }
  }

  showMenu = () => {
    this.setState({
      show: !this.state.show
    });
  }

  close = () => {
    this.setState({
      show: false
    });
  }

  render() {

    return null;
    // return (
    //   <nav>
    //     <FontAwesomeIcon icon={(this.state.show) ? "times" : "bars"} className="toggle" onClick={this.showMenu}/>
    //     <Menus {...this.props} show={this.state.show} items={NAV} close={() => this.close()} />
    //   </nav>
    // );
  }
}

export default withRouter(Nav);