/**
 * Shared Functions
 * @flow
 */
import { EMAIL_URL, IMAGE_ROOT, PROD_URL, SLACK_CONTACT, SLACK_CHECKIN, SMS_URL } from '../data/Data';
import { CLASSES, SCHOOL_TERMS } from '../pages/classes/Classes.data';
import { REVIEWS} from '../components/reviews/Reviews.data';
import { DAYS } from '../data/Data';
import type { FormType, EmailMsg, Product, SlackMsg, SmsMsg, Weekday } from '../types/Types';
import type BugsnagClient from '@bugsnag/js';
import type Moment from 'moment';
import moment from 'moment';

const fn = {
  // Show or hide a form field
  showField: (conditional: boolean, conditions: {name: string, value: boolean}, form: FormType ) => {
    if (!conditional || !conditions || !form) return true

    const inputs = form.inputs;
    
    for (var i=0; i<inputs.length; i++) {
      if (inputs[i].name === conditions.name && inputs[i].value === conditions.value) {
        return true;
      }
    }

    return false;
  },

  // Send an SMS or an email
  sendMessage:  (type: string, body: EmailMsg | SlackMsg | SmsMsg, bugsnagClient: BugsnagClient) => {
    let url = '';
    switch(type) {
      case('email'):
        url = EMAIL_URL;
        break;
      
      case('sms'):
        url = SMS_URL;
        break;

      default:
        return null;
    }

    const options = {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
    };

    fetch(url, options)
      .then(response => response.json())
      .then((response) => {
        
        console.warn(response);

        if (response.hasOwnProperty('error') || (response.hasOwnProperty('name') && response.name=== 'Error')) {
          console.warn(`Error sending ${type}`);
          bugsnagClient.notify(response);
          return false;
        }

        console.warn(`Success sending ${type}`);
        return true;
      })
      .catch((error) => {
        console.error(`Error sending ${type}`);
        console.error(error)
        bugsnagClient.notify(error);
        return false;
      });

    return options;
  },

  notifySlack: (message: {text: string}, bugsnagClient: BugsnagClient, channel: string = 'contact') => {
    const headers = new Headers();
    const req = { 
      method: 'POST',
      headers: headers,
      cache: 'default',
      body: JSON.stringify(message),
    };
    const chan = channel === 'contact' ? SLACK_CONTACT : SLACK_CHECKIN

    fetch(chan, req)
      .then(response => {
        if (response.status === 200) {
          console.warn('Slack notified');
        } else {
          console.warn(response);
          console.warn('Error submitting to slack');
          const error = {
            status: response.status,
            body: response.body
          }
          bugsnagClient.notify(new Error(error))
        }
      });
  },

  loadImage: async (image: string) => {
    const url = `${IMAGE_ROOT}${image}`;
    try {
      const resp = await fetch(url)
      console.log(resp.blob)
      return resp.blob
    } catch (err) {
        console.log(err)
    }
  },

  /**
   * Generate Product JSON LD
   */
  jsonLd: (item: Product, type: string) => {
    let jsonLd = {}; 

    switch(type) {
      case ('Product'): {
        let {
          name,
          alternateName,
          brand,
          id,
          price,
          description,
          sku,
          image
        } = item;
        
        image =  item.image ? `${IMAGE_ROOT}${item.image}` : '';
        const now = moment();
        const year = now.format('YYYY');
        const lastDoY = moment([year, 11]);
        const endDate = moment(lastDoY).endOf('month').toISOString(); 
    
        let review = '';
        for (let i=0; i<REVIEWS.length; i++) {
          if (REVIEWS[i].itemReviewed.name === name || REVIEWS[i].itemReviewed.alternateName === alternateName ) {
            const {
              author,
              reviewBody,
              reviewRating,
              itemReviewed
            } = REVIEWS[i];
            
            review = {
              author,
              reviewBody,
              reviewRating,
              itemReviewed: {
                name: itemReviewed.name,
                url: itemReviewed.url,
              }
            }
            break;
          }
        }

        sku = sku && sku.prod ? item.sku.prod : '';
        
        jsonLd = {
          '@context': 'https://schema.org/',
          '@type': type,
          aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: 4.375,
            reviewCount: 8
          },
          alternateName,
          brand: {
            '@type': 'Thing',
            name: brand,
          },
          description,
          image,
          name,
          offers: {
            availability: 'http://schema.org/InStock',
            price,
            priceCurrency: 'AUD',
            priceValidUntil: endDate,
            url: `${PROD_URL}shop/#${id}`,
          },
          review,
          sku,
          url: `https://aikidomaai.com/shop#${id}`,
        };
        break;
      }

      case('Event'):

        break;

      default: {
        let { image } = item;
        image =  item.image ? `${IMAGE_ROOT}${item.image}` : '';

        jsonLd = {
          ...item,
          '@context': 'https://schema.org/',
          '@type': type,
          image,
        };
      }
    }
    
    return jsonLd;
  },

  // return the next few classes
  // default output is array
  nextClassesString: (quantity: number = 500) => {
    let classes = nextClasses(quantity);
    let strings = [];
   
    for (let i=0; i<classes.length; i++) {
      strings.push(classes[i].formatted);
    }

    return strings;
  },

  /**
   * create a product object for a kids class
   * 
   * @param {string} title - the title of the class
   * @param {int} weeks - the number of weeks in the term
   * 
   * @returns {object} the product object for the kids class
   * 
   */
  createClass: ( title: string, weeks: number ) => {
    const productTitle = `${title} kid's aikido classes`;
    const sku = productTitle.toLowerCase().replace(/\s/g, '-').replace(/'/g, '');
    return {
      name: productTitle,
      alternateName: "Children's Aikido classes",
      brand: 'Aikido Maai',
      id: sku,
      price: weeks * 20,
      sku: sku,
      image: 'aikido-martial-arts-kids-class-400x400.jpg',
      description: `Training for your child (or children) for ${title}. There are ${weeks} weeks of training in the term.`,
      tags: ['kids', 'classes', 'lgbtiq friendly'],
      weeks
    };
  },

  
}

  /**
   * Return the next few classes
   */ 
export const nextClasses = (quantity: number = 500) => {
  let classes = [];
  // Set to midnight so we see today's classes
  const now = moment().hour(0).minute(0).second(0).millisecond(0);

  for (let x=0; x<CLASSES.length; x++) {
  let { days, id, link, location, shortName } = CLASSES[x];

    for (let i=0; i<days.length; i++) {
      const { day, end, start, startHour } = days[i];
      const dates = createDates(day, new Date(), startHour, 1);
      let isKids = id === 'childrens-aikido' ? true : false;
      let include = true;

      if (isKids) {
        for (let i = 0; i < SCHOOL_TERMS.length; i++) {
          const { start } = SCHOOL_TERMS[i];
          const startDate = moment(new Date(start));

          if (now.isBefore(startDate) && startDate.diff(now, 'weeks') > 1) {
            include = false;
            break;
          }
        }
      }

      if (isKids && !include) {
        continue;
      }

      for (let y=0; y<dates.length; y++) {
        const date = moment(dates[y]);

        if (date.isAfter(now)) {
          classes.push({
            date,
            day,
            end,
            link,
            location,
            start,
            title: shortName,
            formatted: `${shortName} - ${date.format('ddd D MMM')} @ ${start}`
          });
        }
      }
    }
  }

  classes.sort(function(a,b) {
    return a.date.isBefore(b.date) ? -1 : a.date.isAfter(b.date) ? 1 : 0;
  });

  // $FlowFixMe
  return classes.slice(0,quantity);
}

/**
 * create Dates
 */
export const createDates = (day: Weekday, start: Date, hour: number, minute: number, length: number = 12): Array<Moment> => {
  const targetDay = DAYS[day];
  let dates: Array<Moment> = [];
  
  if (!targetDay) {
    return dates;
  }

  const startDay = moment(start).isoWeekday();
  const addDays = startDay <= targetDay ? targetDay - startDay : 7 - (startDay - targetDay);

  for (let i = 0; i < length; i++) {
    dates.push(moment(start).add(i, 'weeks').add(addDays, 'days').hour(hour).minute(minute));
  }

  return dates;
}

export default fn;


