import React from 'react';
import {Helmet} from 'react-helmet';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ImageHeading from '../../components/image/ImageHeading';
import DanRank from './DanRank';
import { KYU_RANKS, KIDS_RANKS, SHODAN, SHODAN_CONTENT } from './Syllabus.data';
import SingleRank from './SingleRank';

import { KIDS_CLASSES } from '../shop/Products.data';


class Syllabus extends React.Component {
  renderRanks = (source) => {
    let ranks = [];
    for (let i=0; i<source.length; i++) {
      const rank = source[i];
      ranks.push(<SingleRank key={i} {...rank} />);
    }
    return ranks;
  }

  render() {
    const { location } = this.props;
    let source = [];
    let image = '';
    let title = '';
    let dan = false;
    let content = '';
    let description = '';

    switch(location.pathname) {
      case('/gradings/kids'):
        source = KIDS_RANKS;
        title = 'Kids gradings';
        image = KIDS_CLASSES;
        description = 'Kids grading syllabus';
        break;

      case('/gradings/kyu-ranks'):
        source = KYU_RANKS;
        title = 'Kyu Gradings';
        description = 'Kyu grading syllabus';
        break;

      case('/gradings/shodan'):
        source = SHODAN;
        title = 'Shodan';
        dan = true;
        content = SHODAN_CONTENT;
        description = 'Shodan grading syllabus';
        break;

      default:
        source = null;
        title = 'Gradings';
        description = "Syllabus and techniques for Aikido Maai";
    }

    return (
      <div className="syllabus">
        <Helmet>
          <title>Syllabus</title>
          <meta name="description" content={description} />
        </Helmet>
        <ImageHeading title={title} image={image} />
        <section>
          {
            dan
              ? <DanRank source={source} title={title} content={content} />
              : source
                ? this.renderRanks(source)
                : (
                    <div>
                      <p>Grading syllabus for students</p>
                      <ul>
                        <li>
                          <Link to="/gradings/shodan">Shodan</Link>
                        </li>
                        <li>
                          <Link to="/gradings/kyu-ranks">Kyu ranks</Link>
                        </li>
                        <li>
                          <Link to="/gradings/kids">Kids</Link>
                        </li>
                      </ul>
                    </div>
                  )
          }
        </section>
      </div>
    );
  }
}
Syllabus.propTypes = {
  location: PropTypes.object
};

Syllabus.defaultProps = {
  location: {
    pathname: '/gradings/kyu-ranks',
  }
};

export default Syllabus;
