/**
 * Routes
 * 
 * @flow
 */

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Home from '../pages/home/Home';
import Classes from '../pages/classes/Classes';
import CheckIn from '../pages/check-in/CheckIn';
import Shop from '../pages/shop/Shop';
import Cart from '../pages/shop/cart/Cart';
import Checkout from '../pages/shop/checkout/Checkout';
import Page from '../components/page/Page';
import LearnAikido from '../components/landing-pages/LearnAikido';
import KidsAikido from '../components/landing-pages/KidsAikido';
import LandingPage from '../components/landing-pages/LandingPage';
import KidsAikidoLandingPage from '../components/landing-pages/KidsAikidoLandingPage';
import Location from '../components/location/Location';
import Faq from '../pages/faq/Faq';
import Students from '../pages/students/Students';
import Syllabus from '../pages/syllabus/Syllabus';
import Contact from '../pages/contact/Contact';
import SearchResults from '../components/search/SearchResults';
import {PLACES} from '../data/Data';
import type BugsnagClient from '@bugsnag/js';
import type { Mutate } from '../types/Types';

type Props = {
  bugsnagClient: BugsnagClient,
  mutateBasket: (mutate: Mutate) => *,
}

class Routes extends React.Component<Props> {
  render() {
    const places = [];
    for (let i=0; i<PLACES.length; i++) {
      const place = PLACES[i];
      places.push(
        <Route key={i} path={`/martial-arts-${place}`} exact={true} render={(router) => <LandingPage {...this.props} {...router} />} />
      );

      places.push(
        <Route key={`${i}-kids`} path={`/childrens-martial-arts-${place}`} exact={true} render={(router) => <KidsAikidoLandingPage {...this.props} {...router} />} />
      );
    }

    return (
      <Switch> 
        <Route path="/" exact={true} render={(router) => <Home {...this.props} {...router} />} />
        <Route path='/learn-aikido' render={(router) => <LearnAikido {...this.props} {...router} />} />
        <Route path='/childrens-aikido-classes' render={(router) => <KidsAikido {...this.props} {...router} />} />
        <Route path="/classes" exact={true} render={() => <Classes {...this.props} />} />
        <Route path="/classes/checkin" render={() => <CheckIn {...this.props} />} />
        <Route path="/shop" render={() => <Shop {...this.props} />} />
        <Route path="/cart" render={(router) => <Cart {...this.props} {...router} />} />
        <Route path="/checkout" render={(router) => <Checkout {...this.props} {...router} />} />
        <Route path="/about/location" render={() => <Location {...this.props} />} />
        <Route path="/about/faq" render={() => <Faq {...this.props} />} />
        <Route path="/about/contact" render={() => <Contact {...this.props} />} />
        <Route path="/students" render={(router) => <Students {...this.props} {...router} />} />
        <Route path="/gradings/:rank" render={(router) => <Syllabus {...this.props} {...router} />} />
        <Route path="/gradings" exact={true} render={(router) => <Syllabus {...this.props} {...router} />} />
        <Route path="/search" render={(router) => <SearchResults {...this.props} {...router} />} />

        {/* Geo pages adult */}
        {places}
        
        {/* Redirects old site to new */}
        <Route path="/about/location" exact>
          <Redirect to="/location" />
        </Route>
        
        <Route path='/:page' render={(router) => <Page {...this.props} {...router} />} />
      </Switch>
    );
  }
}

export default Routes;
