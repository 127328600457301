import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Syllabus.css';

class SingleRank extends React.Component {
  constructor(props) {
    super(props);

    this.gradingRef = React.createRef();

    let hash = (window.location.hash) ? window.location.hash.substring(1) : null;

    const { title } = this.props;
    const id = title.replace(/[^a-z0-9]/gmi, '').replace(/\s+/g, '');
    const show = (id === hash) ? true : false;
    
    this.state = {
      id: id,
      show,
    };
  }

  componentDidMount() {
    if (this.state.hash) {
      window.scrollTo(0, this.gradingRef.current.offsetTop);
    }
  }

  toggle = () => {
    this.setState({
      show: !this.state.show
    });
  }

  render() {
    const { id, show } = this.state;
    const {
      content,
      title,
      requirements,
      colour,
      pinning,
      throws,
      suwariWaza,
      weapons
    } = this.props;

    return (
      <div className="rank" id={id} ref={this.gradingRef}>
        <div className={`heading ${(show) ? 'open' : 'closed'}`} onClick={this.toggle} >
          <FontAwesomeIcon icon={(show) ? "minus" : "plus"} onClick={this.showMenu}/>
          <h2>{title}</h2>
          <div className={`colour ${colour}`}>
            {colour}
          </div>
        </div>
          <div className={`techniques${!show ? ' hide' : ''}`}>
            <Group title="Requirements" content={requirements} />
            <Group title="Techniques" content={content} />
            <Group title="Locking and pinning" content={pinning} />
            <Group title="Throws" content={throws} />
            <Group title="Suwari waza" content={suwariWaza} />
            <Group title="Weapons" content={weapons} />
          </div>
      </div>
    );
  }
}

SingleRank.propTypes = {
  title: PropTypes.string,
  requirements: PropTypes.string,
  colour: PropTypes.string,
  pinning: PropTypes.string,
  throws: PropTypes.string,
  suwariWaza: PropTypes.string,
  weapon: PropTypes.string,
}

SingleRank.defaultProps = {
  title: '',
  content: '',
  requirements: '',
  colour: '',
  pinning: '',
  throws: '',
  suwariWaza: '',
  weapons: '',
}

export default SingleRank;

class Group extends React.Component {

  render() {
    const {
      content,
      title,
    } = this.props;

    if (!content) return null;

    return (
      <div className={`group ${title.replace(/\s/, '-').toLowerCase()}`}>
        <div className="label">{title}</div>
        <div className="content" dangerouslySetInnerHTML={{__html: content}} />
      </div>
    );
  }
}
