/**
 * Class Details
 * @flow
 */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from '../image/Image';
import ClassesUpcoming from '../classes-upcoming/ClassesUpcoming';
import { CLASSES } from '../../pages/classes/Classes.data';
import './ClassDetails.css';

const ClassDetails = () => {
  let display= [];

  for (const [index, value] of CLASSES.entries()) {
    let {comments, content, id, image, days, students, title, location} = value;
    // const id = title.replace(/[^a-z0-9]/gmi, '').replace(/\s+/g, '');
    display.push(
      <div key={index} id={id} className="class-details">
        {image ? <Image image={image} /> : null}
        <ul>
          <li className="class-name">
            <h2>{title}</h2>
          </li>
          <li className="days">
            <FontAwesomeIcon icon="clock" />
            <ul>
              {
                days.map((date, index ) => {
                  const { day, start, end } = date;
                  return <li key={index}><strong>{day}</strong> @ {start} – {end}</li>
                })
              }
            </ul>
          </li>
          <ClassesUpcoming days={days} id={id} />
          <li className="location">
            <FontAwesomeIcon icon="map-marker" />
            { 
              location.link
                ? <a href={location.link} target="_blank" rel="noopener noreferrer">{location.title}</a>
                : location.title
            }
          </li>
          <li className="students">
            <FontAwesomeIcon icon="user-ninja" />
            <span>{students}</span>
          </li>
          {
            comments
            ? (
                <li className="comments">
                  <FontAwesomeIcon icon="info-circle" />
                  {comments}
                </li>
              )
            : null
          }
          <li className="content">
            <FontAwesomeIcon icon="comment" />
            <span dangerouslySetInnerHTML={{__html: content}}></span>
          </li>
        </ul>
      </div>
    );
  }
  return display;
}

export default ClassDetails;