export const CONTACT_FORM = {
  name: 'Contact',
  title: 'Send a raven',
  id: 'contact',
  inputs: [
    {
      className: 'name',    
      type: 'text',
      label: 'Name',
      name: 'name',
      placeholder: 'Your name',
      required: true,
    },
    {
      className: 'email',    
      type: 'email',
      label: 'Email',
      name: 'email',
      placeholder: 'you@example.com',
      required: true,
    },
    {
      className: 'mobile',    
      type: 'phone',
      label: 'Mobile',
      name: 'mobile',
      placeholder: '0404 123 456',
      required: true,
    },
    {
      className: 'message',
      type: 'textarea',
      label: 'Your message',
      name: 'message',
      placeholder: 'Your message',
      required: true,
    }
  ],
  success: {
    message: "Thanks for the message. We'll be in touch soon"
  },
  messages: {
    sender: {
      sms: `Thanks for your message, %name%. We'll be in touch soon, Aikido Maai.`,
      email: `Thanks for your message. We've got it and will be in touch soon`,
    }
  }
}