/**
 * Checkout Form fields
 * 
 * @flow
 */
import React from 'react';
import moment from 'moment';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from 'react-stripe-elements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  Basket from '../cart/Basket';
import { EmptyBasket } from '../cart/Basket';
import {
  ADMIN_EMAIL,
  ADMIN_NAME,
  DEFAULT_CHECKOUT_FIELDS,
  CHARGE_URL,
  SUBSCRIPTION_URL,
  EMAIL_REGEX,
  MOBILE_REGEX,
  ZIP_REGEX,
  IS_DEV,
  SLACK_SALES,
  SLACK_TESTING,
  SLACK_ERRORS
} from '../../../data/Data';
import fn from '../../../functions/Functions';
import type {AWSData, BasketType, Fields} from '../../../types/Types';
import type {StripeEvent} from 'react-stripe-elements';
import type {LocalForage} from 'localforage';
import type {BugsnagClient} from '@bugsnag/js';
import type {Moment} from 'moment';
import './CheckoutForm.css';
import '../cart/Cart.css';

const IS_KIDS_CLASS_RE = new RegExp(/kid's aikido classes/);

type Props = {
  basket: BasketType,
  clearBasket: () => *,
  bugsnagClient: BugsnagClient,
  jest: boolean,
  localforage: LocalForage,
  stripe: {
    createToken: (data: {}) => Promise<any>,
  }
};

type State = {
  body: {
    charge: {
      description: string,
      amount: number,
    },
    customer: {},
    subscription: {
      items: Array<{}>,
      billing_cycle_anchor: number,
      expand: Array<string>,
      prorate: boolean,
    },
    token: string,
  },
  description: string,
  error: boolean,
  errorMessage: string,
  errorStripe: boolean,
  errorStripeMesage: string,
  fields: Fields,
  hasSubscription: boolean,
  submitting: boolean,
  subscriptionSuccess: boolean,
  subscriptionSuccessObject: {
    subscription: {
      items: {
        data: *
      },
      latest_invoice: {
        invoice_pdf: string,
      }
    },
  },
  success: boolean,
  successObject: {
    charge: {
      amount: number,
      description: string,
      payment_method_details: {
        card: {
          last4: string,
        },
      },
      receipt_url: URL,
      source: {
        brand: string,
      },
    },
  },
};

class CheckoutFormFields extends React.Component<Props, State> {
  static defaultProps = {
    basket: {
      items: []
    },
    clearBasket: () => null,
    jest: false,
    localforage: {
      getItem: () => null,
      setItem: () => null
    },
    stripe: {
      createToken: () => null
    },
  };

  // $FlowFixMe
  messaging: React.RefObject;

  constructor(props: Props) {
    super(props);

    this.messaging = React.createRef();
    // $FlowFixMe
    this.submit = this.submit.bind(this);

    this.state = {
      body: {},
      description: '',
      error: false,
      errorMessage: 'Please fill in all required fields',
      errorStripe: false,
      errorStripeMesage: 'Error charging card.',
      fields: {
        ...DEFAULT_CHECKOUT_FIELDS
      },
      hasSubscription: false,
      submitting: false,
      subscriptionSuccess: false,
      subscriptionSuccessObject: {},
      success: false,
      successObject: {},
    }
  }

  componentDidMount() {
    const { fields } = this.state;
    const { localforage } = this.props;

    if (!localforage) return null;

    for (let property in fields) {
      if (fields.hasOwnProperty(property) && fields[property].valid !== true) {
        localforage.getItem(property)
          .then(
            (value) =>  this.updateField(property, value, false)
          )
          .catch(
            (error) => {
              this.props.bugsnagClient.notify(error);
            }
          )
      }
    }
  }

  /**
   * Update a field
   */
  updateField = (field: string, value: string, setLocalForage: boolean = true) => {

    if (value === null ) {
      return value;
    }

    const { localforage } = this.props;
    let valid = false;
    let error = false;

    switch(field) {
      case ('email'):
        valid = EMAIL_REGEX.test(value);
        error = !valid;
        break;
      
      case('zip'):
        valid = ZIP_REGEX.test(value);
        value = valid ? value.substring(0,4) : '';
        error = !valid;
        break;

      case('mobile'):
        valid = MOBILE_REGEX.test(value);
        error = !valid;
        break;
    
      default:
        valid = value.length > 0 ? true : false;
        error = !valid;
    }

    this.setState({
      error,
      fields: {
        ...this.state.fields,
        [field]: {
          value,
          valid: valid
        }        
      }
    });

    if (setLocalForage) {
      localforage.setItem(field, value);
      return 'lfSet';
    }
    return true;
  }

  /**
   * onChange
   */
  onChange = ({ target }: SyntheticInputEvent<>, field: string) => {
    this.updateField(field, target.value);
  }

  /**
   * onBlur
   */
  onBlur = ({ target }: SyntheticInputEvent<>, field: string) => {
      this.updateField(field, target.value);
      this.fieldsValid();
  }

  /**
   * onChange card number
   * 
   * @param {string} item - card, cvc, or expiry
   * @param {object} the change returned from the strip Element
   * 
   * @returns {*} the validity of the field - for testing 
   */
  onCardChange = (item: string, change: StripeEvent) => {
    const valid = change.complete;
    this.setState({
      fields: {
        ...this.state.fields,
        [item]: {
          valid
        }
      }
    });
    return valid;
  }

  /**
   * Validate fields
   * 
   * @param {object} the fields
   * 
   * @returns {boolean} error
   */
  fieldsValid = (fields: Fields = this.state.fields) => {
    let error = false;

    for (let property in fields) {
      if (fields.hasOwnProperty(property) && fields[property].valid !== true && fields[property].required) {
        error = true;
      }
    }
    return error;
  }

  /**
   * Create token data
   * 
   * @param {object} fields
   * 
   * @returns (object) data object to create stripe token
   */
  tokenData = (fields: Fields) => {
    const data = {
      name: fields.name.value,
      email: fields.email.value,
      address_city: fields.suburb.value,
      address_country: 'AU',
      address_line1: fields.street.value,
      address_state: 'VIC',
      address_zip: fields.zip.value,
    }

    return data;
  }

  /**
   * Submit the form is a two step process
   * 
   * Send to Stripe and get a token
   * then send to AWS lambda to process the transaction (again through stripe)
   * 
   */
  async submit() {
    const { fields, submitting } = this.state;
    const error = this.fieldsValid(fields);

    this.setState({
      error
    });

    if (error || submitting) return false;

    const data = this.tokenData(fields);

    this.setState({
      submitting: true
    });

    let { token } = await this.props.stripe.createToken(data);
    // check we have a token at this point
    this.formatSend(token, moment());
  }

  /**
   * sendData
   * 
   * @param {string} token - supploed by Stripe
   * @param (moment) now as moment object - passed in to make testing easier
   */
  formatSend = (token: string, now: Moment) => {
    const { basket } = this.props;
    const {
      childName,
      customerID,
      email,
      name,
      street,
      suburb,
      zip
    } = this.state.fields;
    const items = [...basket.items];
    let descriptions = [];

    let subscription = {
      items: [],
      billing_cycle_anchor: now.add(1, 'months').startOf('month').format('X'),
      expand: ['latest_invoice.payment_intent'],
      prorate: false
    };

    if (customerID.value) {
      subscription = {
        ...subscription,
        customer: customerID.value,
      }
    }

    let customer = {
      name: name.value,
      address: {
        city: suburb.value,
        line1: street.value,
        postal_code: zip.value,
        state: 'VIC',
        country: 'Australia',
      },
      email: email.value,
    };

    for (let i=0; i<items.length; i++) {
      const item = items[i];
      const forKid = IS_KIDS_CLASS_RE.test(item.name) ? ` for ${childName.value}` : '';
      if (!item.subscription) {
        descriptions.push(`${item.quantity} x ${item.name}${forKid}`);
      } else {
        // is this dev or prod.
        // Testing here to make jest testing workable
        const isDev = IS_DEV.test(window.location.href) ? true : false;

        subscription.items.push({
          plan: isDev ? item.sku.dev : item.sku.prod 
        });
      }
    }
    const description = descriptions.join(', ')

    const charge = {
      description,
      amount: basket.fixedTotal * 100, // Stripe values are in cents
    }

    const body = {
      charge,
      customer,
      subscription,
      token,
    }

    this.setState({
      body,
      description,
    }, () => this.processBasket());

    return body;
  }

  /**
   * Process the items in the basket
   */
  processBasket = () => {
    if (this.state.body.subscription.items.length > 0 ) {
      this.setState({
        hasSubscription: true
      }, () => this.createSubscription());
    } else {
      this.createCharge();
    }
  }

  /**
   * Create subscription via AWS lambda
   */
  createSubscription = () => {
    const options = this.createOptions();

    fetch(SUBSCRIPTION_URL,options)
      .then(response => response.json())
      .then(data => this.fetchSubResult(data))
      .catch(error => this.error(error));
  }

  /**
   * Send charge to AWS lambda
   */
  createCharge = () => {
    const options = this.createOptions();

    fetch(CHARGE_URL,options)
      .then(response => response.json())
      .then(data => this.fetchResult(data))
      .catch(error => this.error(error));
  }

  /**
   * Create options
   */
  createOptions = () => {
    return {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'omit',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(this.state.body),
      redirect: 'follow',
      referrer: 'no-referrer',
    };
  }

  /**
   * Fetch result
   * 
   * @param object data - the data returned from the AWS Lambda function
   */
  fetchResult = (data: AWSData) => {
    if (data.error) {
      this.error(data);
      return;
    }

    this.setState({
      success: true,
      successObject: data,
      submitting: false,
    });

    const {
      name,
      email,
    } = this.state.fields;

    const {
      amount,
      description,
      payment_method_details,
      receipt_url,
      source,
    } = data.charge;

    const names = name.value.split(' ');

    const firstName = names[0] ? names[0] : name.value;
    const message = `
      Hi ${firstName}${"\r\n"}
      Your purchase of ${description} from Aikido Maai has been successful${"\r\n"}
      ${(amount/100).toFixed(2)} charged to ${source.brand} card **** **** **** ${payment_method_details.card.last4}.${"\r\n"}
      You can view you tax invoice here: ${receipt_url}.${"\r\n"}
      Thankyou for supporting the dojo. By supporting the dojo you are helping people become their best selves (you included).
    `;
    const messageHtml = `
      <p>Hi ${firstName},</p>
      <p>Your purchase of ${description} from Aikido Maai has been successful.</p>
      <p>${(amount/100).toFixed(2)} charged to ${source.brand} card **** **** **** ${payment_method_details.card.last4}.</p>
      <p>You can view you tax invoice here: ${receipt_url}.</p>
      <p>Thankyou for supporting the dojo. By supporting the dojo you are helping people become their best selves (you included).</p>
    `;
    const subject = 'Successful purchase at AikidoMaai';
  
    // Send confirmation email
    this.sendMessage(subject, message, messageHtml);

    // Notify Slack
    const text = `*New sale* $${(amount/100).toFixed(2)} for ${description} To ${name.value}:  ${email.value}`;
    this.notifySlack(text);

    // Clear the basket
    this.props.clearBasket();
  }

   /**
   * Fetch subscription result
   * 
   * @param object data - the data returned from the AWS Lambda function
   */
  fetchSubResult = (data: AWSData) => {
    if (data.error) {
      this.error(data);
      return;
    }

    this.setState({
      subscriptionSuccess: true,
      subscriptionSuccessObject: data,
    });

    const {
      name,
      email,
      mobile,
    } = this.state.fields;

    const subscriptions = [...data.subscription.items.data]
    const subs =  subscriptions.length > 1 ? 'subscriptions' : 'subscription';

    let plans = [];
    let totalSub = 0;
    for (let i=0; i<subscriptions.length; i++) {
      plans.push(subscriptions[i].plan.nickname);
      totalSub += subscriptions[i].plan.amount;
    }

    const invoice = data.subscription && data.subscription.latest_invoice ? data.subscription.latest_invoice.invoice_pdf : '';
    const invoiceText = invoice ? `You can view your next  tax invoice here: ${invoice}.` : '';
    const names = name.value.split(' ');
    const firstName = names[0] ? names[0] : name.value;
    const message = `
      Hi ${firstName}${"\r\n"}
      Your subscription to ${plans.join(', ')} from Aikido Maai has been successful${"\r\n"}
      ${(totalSub/100).toFixed(2)} will be charged to your credit card on the 1st of each month while you train with us.${"\r\n"}
      ${invoiceText ? `${invoiceText}${"\r\n"}` : ''}
      Thankyou for supporting the dojo. By supporting the dojo you are helping people become their best selves (you included).
    `;
    const messageHtml = `
      <p>Hi ${firstName},</p>
      <p>Your subscription to ${plans.join(', ')} from Aikido Maai has been successful.</p>
      <p>${(totalSub/100).toFixed(2)} will be charged to your card on the 1st of each month from now on.</p>
      ${invoiceText ? `<p>${invoiceText}.</p>` : ''}
      <p>Thankyou for supporting the dojo. By supporting the dojo you are helping people become their best selves (you included).</p>
    `;
    const subject = 'Successful subscription to Aikido Maai';
  
    // Send confirmation email
    this.sendMessage(subject, message, messageHtml);

    // Notify Slack
    const text = `*New ${subs}* to "${plans.join(', ')}" for ${name.value}:  ${email.value}, ${mobile.value}`;
    this.notifySlack(text);

    // save customer ID
    this.saveCustomerID(data.customer.id);

    // Process other products
    this.createCharge();
  }

  /**
   * Save Stripe customer ID to localforage
   */
  saveCustomerID = (customerID: string) => {
    if (!customerID) {
      return;
    }
    this.props.localforage.setItem('customerID', customerID);
    this.setState({
      fields: {
        ...this.state.fields,
        customerID: {
          required: false,
          value: customerID,
          valid: true,
        },
      }
    });
  }

  /**
   * Error 
   * @params object - error ye olde error
   */
  error = (error: AWSData) => {
    this.setState({
      errorStripe: true,
      errorStripeMesage: error.error,
      success: false,
      submitting: false,
    });

    // Don't notify slack or bugsnag for jest tests.
    if (this.props.jest){
      return true;
    }

    const {description, fields } = this.state;
    const {name, email, mobile} = fields;
    // Generate meaningful error message

    // Notify Bugsnag
    const errorMessage = `Stripe error: *${error.error}* on purchase: *${description}* for ${name.value}: ${email.value}, ${mobile.value}`;

    const sendError = new Error (errorMessage);
    this.props.bugsnagClient.notify(sendError);

    // Notify Slack
    this.notifySlack(errorMessage, true);
  }

  /**
   * Send a message
   */
  sendMessage = (subject: string, message: string, messageHtml: string) => {
    const { name, email} = this.state.fields;
    const body = {
      from: {
        name: ADMIN_NAME,
        email: ADMIN_EMAIL
      },
      message,
      messageHtml,
      subject,
      to: {
        email: email.value,
        name: name.value,
      }
    };

    fn.sendMessage('email', body);
  }

  /**
   * Notify Slack
   */
  notifySlack = (text: string, error: boolean = false) => {
    // Use #testing slack channel if testing
    // is this dev or prod.
    // Testing here to make jest testing workable
    const isDev = IS_DEV.test(window.location.href) ? true : false;
    const slackUrl = error ? SLACK_ERRORS : this.props.jest || isDev ? SLACK_TESTING : SLACK_SALES;

    const content = {
      text
    };
    const headers = new Headers();
    const req = { 
      method: 'POST',
      headers: headers,
      cache: 'default',
      body: JSON.stringify(content),
    };

    fetch(slackUrl, req)
      .then(response => {
        if (response.status === 200) {
          console.warn('Slack notified');
        } else {
          const error = {
            status: response.status,
            body: response.body
          }
          this.props.bugsnagClient.notify(new Error(error))
        }
      });
  }

  render() {
    const { basket } = this.props;

    const {
      error,
      errorMessage,
      errorStripe,
      errorStripeMesage,
      fields,
      subscriptionSuccess,
      subscriptionSuccessObject,
      submitting,
      success,
      successObject
    } = this.state;

    const {
      card,
      childName,
      customerID,
      cvc,
      email,
      expiry,
      name,
      mobile,
      street,
      suburb,
      zip
    } = fields;

    const cardStyle = {
        base: { fontSize: '18px' }
    };

    let renderSuccess = [];
    let i=0;
    if (subscriptionSuccess) {
      const data = {...subscriptionSuccessObject};
      const subscriptions = [...data.subscription.items.data]
  
      let plans = [];
      let totalSub = 0;
      for (let i=0; i<subscriptions.length; i++) {
        plans.push(subscriptions[i].plan.nickname);
        totalSub += subscriptions[i].plan.amount;
      }

      const invoice = data.subscription && data.subscription.latest_invoice ? data.subscription.latest_invoice.invoice_pdf : '';

      renderSuccess.push(
        <div key={i++} className="successful-subscription">
          <h3>Successful subscription</h3>
          <p>Your subscription to <span className="plans">{plans.join(' & ')}</span> from Aikido Maai has been successful.</p>
          <p><span className="amount">${(totalSub/100).toFixed(2)}</span> will be charged to your credit card on the 1st of each month while you train with us.</p>
          {invoice ? <div className="receipt">You can <a href={invoice} target="_blank" rel="noopener noreferrer">view your next tax invoice </a></div> : null}
        </div>
      );
    }

    if (success) {
      const {
        amount,
        description,
        payment_method_details,
        receipt_url,
        source,
      } = successObject.charge;

      const items = description.split(', ');

      let bought = [];
      for (let i=0; i<items.length; i++) {
        bought.push(<li key={i}>{items[i]}</li>);
      }

      renderSuccess.push(
        <div key={i++} className="successful-payment">
          <h3>Successful payment</h3>
          <div>
            <span className="amount">${(amount/100).toFixed(2)}</span>
            <span className="card">charged to {source.brand} card <span className="number">**** **** **** {payment_method_details.card.last4}</span></span>
            <span className="description">for: <ul>{bought}</ul></span>
            <div className="receipt"><a href={receipt_url} target="_blank" rel="noopener noreferrer">View your tax invoice</a></div>
          </div>
         
        </div>
      );

      return(
        <div className="successful-payment">
           <h2>Thank you for your payment!</h2>
            <p>By supporting the dojo you are helping people become their best selves (you included).</p>
            <div className="details">
               {renderSuccess}
            </div>
        </div>
      );

    }

    if ( basket.items.length === 0) {
      return <EmptyBasket />;
    }
    
    // Is there a kids class product in the cart
    let hasKids = false;
    const { items } = basket;
    for (let i=0; i<items.length; i++) {
      if (IS_KIDS_CLASS_RE.test(items[i].name)) {
        hasKids = true;
      }
    }

    return (
      <div className="checkout-form">
        <div className="info">
        <div className="group-label">
          <FontAwesomeIcon icon="user-ninja" />
          Your details
        </div>
          <div className="address">
            <div className="input-row">
              <label htmlFor="name">Name<span className="required">*</span></label>
              <input
                className={name.valid === false ? 'invalid' : ''}
                name="name"
                value={name.value}
                onChange={(e) => this.onChange(e, 'name')}
                onBlur={(e) => this.onBlur(e, 'name')}
                placeholder="Your name"
                required={true}
              />
            </div>
            {
              hasKids
                ? (
                    <div className="input-row">
                      <label htmlFor="childName">Child's name<span className="required">*</span></label>
                      <input
                        className={childName.valid === false ? 'invalid' : ''}
                        name="childName"
                        value={childName.value}
                        onChange={(e) => this.onChange(e, 'childName')}
                        onBlur={(e) => this.onBlur(e, 'childName')}
                        placeholder="Your child's name"
                        required={true}
                      />
                    </div>
                )
                : null
            }
            
            <div className="input-row">
              <label htmlFor="customerID">CustomerID</label>
              <input
                name="customerID"
                value={customerID.value}
                onChange={(e) => this.onChange(e, 'customerID')}
                onBlur={(e) => this.onBlur(e, 'customerID')}
                placeholder=""
                required={false}
              />
            </div>
            <div className="input-row">
              <label htmlFor="name">Mobile<span className="required">*</span></label>
              <input
                className={mobile.valid === false ? 'invalid' : ''}
                name="name"
                value={mobile.value}
                onChange={(e) => this.onChange(e, 'mobile')}
                onBlur={(e) => this.onBlur(e, 'mobile')}
                placeholder="0404 123 456"
                required={true}
              />
            </div>
            <div className="input-row">
              <label htmlFor="email">Email<span className="required">*</span></label>
              <input
                className={email.valid === false ? 'invalid' : ''}
                name="email"
                type="email"
                value={email.value}
                onChange={(e) => this.onChange(e, 'email')}
                onBlur={(e) => this.onBlur(e, 'email')}
                placeholder="you@domain.com"
                required={true}
              />
            </div>
            <div className="input-row">
              <label htmlFor="street">Street<span className="required">*</span></label>
              <input
                className={street.valid === false ? 'invalid' : ''}
                name="street"
                value={street.value}
                onChange={(e) => this.onChange(e, 'street')}
                onBlur={(e) => this.onBlur(e, 'street')}
                placeholder="14 Montgomery St"
                required={true} />
            </div>
            <div className="input-row">
              <label htmlFor="suburb">Suburb<span className="required">*</span></label>
              <input
                className={suburb.valid === false ? 'invalid' : ''}
                name="suburb" 
                value={suburb.value}
                onChange={(e) => this.onChange(e, 'suburb')}
                onBlur={(e) => this.onBlur(e, 'suburb')}
                placeholder="Mt Waverley"
                required={true}
                />
              <label htmlFor="zip">Postcode<span className="required">*</span></label>
              <input
                className={zip.valid === false ? 'invalid' : ''}
                name="zip"
                value={zip.value}
                onChange={(e) => this.onChange(e, 'zip')}
                onBlur={(e) => this.onBlur(e, 'zip')}
                placeholder="3149"
                required={true}
                />
            </div>
          </div>
          <div className="group-label">
            <FontAwesomeIcon icon="credit-card" />
            Credit card details
          </div>
          <div className="input-row number">
            <div className={card.valid === false ? 'invalid' : ''}>
              <span className="label">Number<span className="required">*</span></span>
              <CardNumberElement
                style={cardStyle}
                onChange={(card) => this.onCardChange('card', card)}
                onBlur={this.fieldsValid}
              />
            </div>
            <div className={`input-row expiry${expiry.valid === false ? ' invalid' : ''}`}>
              <span className="label">Expiry<span className="required">*</span></span>
              <CardExpiryElement
                style={cardStyle}
                onChange={(expiry) => this.onCardChange('expiry', expiry)}
                onBlur={this.fieldsValid}
              />
            </div>
            <div className={`input-row cvc${cvc.valid === false ? ' invalid' : ''}`}>
              <span className="label">CVC<span className="required">*</span></span>
              <CardCvcElement
                style={cardStyle}
                onChange={(cvc) => this.onCardChange('cvc', cvc)}
                onBlur={this.fieldsValid}
                />
            </div>
          </div> 
        </div>
        <Basket {...this.props} />
        {
          error 
            ? <div className="error"><FontAwesomeIcon icon="exclamation-triangle" /> {errorMessage}</div>
            : null
        }
        {
          errorStripe
            ? <div className="stripe-error">
                <FontAwesomeIcon icon="exclamation-triangle"/> {errorStripeMesage}
              </div>
            : null
        }
        <div
          className={`button${error ? ' inactive' : ''}${(submitting) ? ' sending' : ''}`}
          onClick={this.submit}>
          {
          submitting
            ? <FontAwesomeIcon icon="spinner" spin={true} className="fa-2x" />
            : <span>Pay now<FontAwesomeIcon icon="chevron-right" /></span>
          }
        </div>
      </div>
    );
  }
}

export default CheckoutFormFields;
